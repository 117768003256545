'use client';

import classes from './index.module.scss';

import React from 'react';
import type { ImageResolutionType } from '../Avatar/Avatar.component';
import cnj from '../utils/cnj';
import { applyResolutionToImageSrc } from '@lobox/utils';
import makeImageSrc from '@shared/utils/makeImageSrc';

type ModifiedHTMLImageElement = Partial<HTMLImageElement>;

interface ImageProps extends ModifiedHTMLImageElement {
  resolution?: ImageResolutionType;
  className?: string;
  src?: string;
}

const Image = ({
  src,
  className,
  resolution = 'small',
  loading,
  placeholderComponent,
  ...rest
}: ImageProps) => {
  const convertedSrc = applyResolutionToImageSrc(src, resolution);

  if (!src && !!placeholderComponent) {
    return <>{placeholderComponent}</>;
  }
  return (
    <img
      {...rest}
      src={makeImageSrc(convertedSrc)}
      className={cnj(classes.image, className)}
      loading="lazy"
    />
  );
};

export default Image;
