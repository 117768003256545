'use client';

import { createMedia } from '@artsy/fresnel';
import { midDesktopBreakpoint, tabletBreakpoint } from '../helpers/theme';

const ExampleAppMedia = createMedia({
  interactions: {
    landscape: 'not all and (orientation: landscape)',
    portrait: 'not all and (orientation: portrait)',
    hover: '(hover: hover)',
    notHover: '(hover: none)',
  },
  breakpoints: {
    tablet: 0,
    midDesktop: tabletBreakpoint,
    desktop: midDesktopBreakpoint,
  },
});
{
  /*
          vaghty migim "at" mobile yani az 0 ta tablet
          vaghty migim "lessThan" tablet yani khodesh nist zirishe ke mishe mobile. lessThan midDesktop yani tablet o mobile
          vaghty migim "greaterThan" yani khodesh nist va az un size be bala - greaterThan mobile yani tablet+midDesktop+desktop
          vaghty migim "greaterThanOrEqual" yani ham khodesh ham balayiash
          vaghty migim "between" yani faghat un 2 ta masaln.between={["mobile", "tabet"]} az sefr ta entehaye mobile
        */
}

// Make styles for injection into the header of the page
export const mediaStyles = ExampleAppMedia.createMediaStyle();

export const { Media, MediaContextProvider } = ExampleAppMedia;

// example
// <Media between={['midDesktop', 'desktop']}>
//   <CmpA />
// </Media>
// <Media greaterThanOrEqual="desktop">
//   <CmpB />
// </Media>
