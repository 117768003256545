import classes from './index.module.scss';

import React from 'react';
import cnj from '../utils/cnj';
import type { TypographyProps } from '../Typography';
import Typography from '../Typography';
import type { ButtonProps } from '../Button';
import Button from '../Button';
import Flex from '../Flex';
import Divider from '../Divider';

type TextProps = Omit<TypographyProps, 'children'> & {
  objectId?: string;
};

interface NotFoundProps {
  title: string;
  message: string;
  image: React.ReactNode;
  primaryActionProps?: ButtonProps;
  secondaryActionProps?: ButtonProps;
  action?: React.ReactNode;
  className?: string;
  imageWrapClassName?: string;
  titleProps?: TextProps;
}

const NotFound = ({
  title,
  message,
  image,
  primaryActionProps,
  secondaryActionProps,
  action,
  className,
  imageWrapClassName,
  titleProps = {},
}: NotFoundProps): JSX.Element => {
  return (
    <Flex className={cnj(classes.notFoundRoot, className)}>
      <Flex className={cnj(classes.notFoundImgWrap, imageWrapClassName)}>
        {image}
      </Flex>
      <Divider />
      <Typography
        className={classes.title}
        font="bold"
        size={16}
        height={20}
        color="secondaryText"
        {...titleProps}
      >
        {title}
      </Typography>
      <Typography
        className={classes.message}
        size={15}
        height={21}
        textAlign="center"
      >
        {message}
      </Typography>
      {(primaryActionProps || secondaryActionProps) && (
        <Flex className={classes.iconWrap}>
          {secondaryActionProps && (
            <Button
              schema="ghost"
              leftIcon="chevron-left"
              className={classes.back}
              {...secondaryActionProps}
            />
          )}
          {primaryActionProps && (
            <Button schema="primary-blue" {...primaryActionProps} />
          )}
        </Flex>
      )}
      {action}
    </Flex>
  );
};

export default NotFound;
