import React from 'react';

const Person = (props: any) => {
  const { fill, ...rest } = props;

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 22 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.614.048H10.38c-2.017 0-3.864 1.076-4.782 2.786a4.897 4.897 0 00-.44 3.526l1.023 4.048c.535 2.12 2.526 3.613 4.815 3.613 2.289 0 4.28-1.493 4.815-3.613l1.023-4.048a4.897 4.897 0 00-.44-3.526C15.477 1.124 13.63.048 11.614.048zM7.254 16.278c-5.823 0-8.29 3.74-6.59 9.045 1.112 3.473 4.477 7.465 8.29 7.465h4.092c3.812 0 7.178-3.992 8.29-7.465 1.7-5.304-.767-9.045-6.59-9.045H7.254z"
        fill={fill}
      />
    </svg>
  );
};

export default Person;
