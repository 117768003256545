import React from 'react';
import assign from 'lodash/assign';
import { Media } from '../utils/media';
import cnj from '../utils/cnj';

interface MediaProps {
  [key: string]: any;
  children: React.ReactElement;
}

const CustomMedia: React.FC<MediaProps> = ({ children, ...rest }) => {
  return (
    // @ts-ignore
    <Media {...rest}>
      {(mediaClassNames: string /* , renderChildren: any */) => {
        // return renderChildren
        //   ? React.cloneElement(<>{children}</>, { className: mediaClassNames })
        //   : null;
        return (
          <>
            {React.Children?.map(children, (child) => {
              if (!child) {
                return;
              }
              // @ts-ignore
              return React.cloneElement(
                child,
                assign(
                  {
                    // @ts-ignore
                    className: cnj(
                      // @ts-ignore
                      child?.props?.className,
                      mediaClassNames
                    ),
                  },
                  rest.lessThan === 'midDesktop'
                    ? {
                        containerElementClass: cnj(
                          // @ts-ignore
                          child?.props?.containerElementClass,
                          mediaClassNames
                        ),
                      }
                    : {}
                )
              );
            })}
          </>
        );
      }}
    </Media>
  );
};

export default CustomMedia;
