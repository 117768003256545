import React, { forwardRef } from 'react';
import isNumber from 'lodash/isNumber';
import type { FlexProps } from '../../Flex';
import Flex from '../../Flex';
import cnj from '../../utils/cnj';
import { makeDynamicStyles } from '@shared/uikit/utils/makeDynamicStyles';
import classes from './Box.component.module.scss';
import useMedia from '@lobox/uikit/utils/useMedia';
export interface BoxProps extends FlexProps {
  className?: string;
  children: React.ReactNode;
  radius?: number;
  padding?:
    | number
    | {
        mobile?: number;
        tablet?: number;
        midDesktop?: number;
        desktop?: number;
      };
}

const Box = (
  { className, children, radius = 12, padding, ...rest }: BoxProps,
  ref: any
) => {
  const { isMoreThanTablet, isMidDesktop } = useMedia();

  const styles = isMidDesktop
    ? makeDynamicStyles({
        borderRadius: radius,
        padding: (isNumber(padding) && `${padding}px`) || padding?.desktop,
      })
    : isMoreThanTablet
      ? makeDynamicStyles({
          borderRadius: radius,
          padding: (isNumber(padding) && `${padding}px`) || padding?.midDesktop,
        })
      : makeDynamicStyles({
          padding: (isNumber(padding) && `${padding}px`) || padding?.mobile,
        });

  return (
    <Flex
      ref={ref}
      className={cnj(classes.boxRoot, className)}
      {...styles}
      {...rest}
    >
      {children}
    </Flex>
  );
};

export default forwardRef<any, BoxProps>(Box);
