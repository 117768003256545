import React from "react";

export type UseCssVariablesProps = {
  scope: string;
  variables: Record<string, unknown>;
}

const _defaultVariables = {};

const useCssVariables = ({ scope, variables: v = _defaultVariables }: UseCssVariablesProps) => {
  const _keys = Object.keys(v);
  const _scopeVariables = _keys.reduce((acc, curr) => `${acc}--${curr}:${v[curr]};`, '');

  return !(scope && _keys.length) ? null : (
    <style>{`.${scope}{${_scopeVariables}}`}</style>
  );
}

export default useCssVariables;
