import classes from './Search.component.module.scss';

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useOutsideListener, useTranslation } from '@lobox/utils';
import isFunction from 'lodash/isFunction';
import type { FocusEvent, HtmlHTMLAttributes } from 'react';
import cnj from '../utils/cnj';
import type { IconProps } from '../Icon';
import Icon from '../Icon';
import Flex from '../Flex';

export interface SearchInputProps {
  className?: any;
  inputStyle?: any;
  placeholder?: string;
  inputId?: string;
  onChange?: (val: string) => void;
  onClearSearch?: () => void;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  variant?: 'circle' | 'square';
  toggleable?: boolean;
  onToggle?: (isOpen: boolean) => void;
  toggleVariant?: 'outline' | 'solid';
  size?: 'large' | 'small';
  inputProps?: any;
  onClickOutsideCallback?: (...args: any[]) => any;
  clickOutsideExceptionList?: Array<string>;
  defaultValue?: string;
  trashIconId?: string;
  inputRootClassName?: string;
  trashIconProps?: IconProps;
  searchIconProps?: IconProps;
}

const SearchInput = (
  {
    className,
    inputStyle,
    onChange,
    onClearSearch,
    onFocus,
    variant = 'circle',
    toggleable = false,
    onToggle,
    toggleVariant = 'outline',
    placeholder,
    inputId,
    size = 'large',
    inputProps,
    onClickOutsideCallback,
    defaultValue = '',
    trashIconId,
    clickOutsideExceptionList = [],
    inputRootClassName,
    trashIconProps = {},
    searchIconProps = {},
  }: SearchInputProps,
  ref: any
) => {
  const { t } = useTranslation();
  const wrapperRef = useRef<HtmlHTMLAttributes<HTMLDivElement>>();
  const inputRef = useRef<HTMLInputElement>();
  const [isOpenInput, toggleInput] = useState(!toggleable);
  const [value, setValue] = useState(defaultValue);

  const handleFocus = (e: any) => {
    if (isFunction(onFocus)) {
      onFocus(e);
    }
  };
  const handleChanged = (e: any) => {
    const val = e.target.value;
    setValue(val);
    if (isFunction(onChange)) {
      onChange(val);
    }
  };

  useImperativeHandle(ref, () => ({
    handleChange: (val: string) => {
      setValue(val);
      if (isFunction(onChange)) {
        onChange(val);
      }
    },
    handleFocus: () => {
      inputRef.current?.focus();
    },
  }));

  const clear = () => {
    onClearSearch?.();
    setValue('');
    if (onChange) {
      onChange('');
    }
  };
  const handleToggleInput = () => {
    toggleInput(true);
    if (onToggle) onToggle(true);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 500);
  };

  useEffect(() => {
    toggleInput(!toggleable);
  }, [toggleable]);

  useOutsideListener(
    wrapperRef?.current as any,
    () => {
      if (isFunction(onClickOutsideCallback)) {
        onClickOutsideCallback();
      }
      if (toggleable) {
        toggleInput(false);
        if (isFunction(onToggle)) {
          onToggle(false);
        }
      }
    },
    clickOutsideExceptionList
  );

  return (
    <Flex ref={wrapperRef} className={cnj(classes.wrapper, className)}>
      <Flex
        className={cnj(
          classes.inputRoot,
          size === 'large' && classes.inputRootLarge,
          toggleable && !isOpenInput && classes.widthZero,
          inputRootClassName
        )}
      >
        <input
          {...inputProps}
          id={inputId}
          ref={inputRef}
          value={value}
          placeholder={placeholder || t('search')}
          className={cnj(
            classes.input,
            variant === 'square' && classes.inputSquare,
            !!value && classes.inputActive,
            inputStyle
          )}
          onChange={handleChanged}
          onFocus={handleFocus}
        />
        {value && (
          <Flex
            ref={ref}
            id={trashIconId}
            onClick={clear}
            onKeyDown={clear}
            role="button"
            tabIndex="-1"
          >
            <Icon
              name="trash"
              type="fal"
              color="primaryText"
              size={14}
              {...trashIconProps}
              className={cnj(
                classes.clean,
                size === 'large' && classes.cleanLarge,
                isOpenInput && classes.visibleClean,
                trashIconProps?.className
              )}
            />
          </Flex>
        )}
        {!value && (
          <Flex>
            <Icon
              name="search"
              type="fal"
              color="inputPlaceholder"
              size={14}
              {...searchIconProps}
              className={cnj(
                classes.searchIcon,
                size === 'large' && classes.searchIconLarge,
                isOpenInput && classes.visibleClean,
                searchIconProps?.className
              )}
            />
          </Flex>
        )}
      </Flex>
      {toggleable && !isOpenInput && (
        <Flex
          className={cnj(
            classes.toggleSearch,
            size === 'large' && classes.toggleSearchLarge,
            toggleVariant && classes.toggleVariantWidth,
            toggleVariant === 'solid' && classes.noBorder
          )}
          onClick={handleToggleInput}
        >
          <Icon
            name="search-light"
            type="fal"
            color="inputPlaceholder"
            size={toggleVariant === 'solid' ? 20 : 14}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default forwardRef<any, SearchInputProps>(SearchInput);
