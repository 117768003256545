import classes from './index.module.scss';

import React, {
  cloneElement,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { preventClickHandler, useStateCallback } from '@lobox/utils';
import Popper from '@material-ui/core/Popper';
import isFunction from 'lodash/isFunction';
import type { PopperPlacementType } from '@material-ui/core/Popper';
import cnj from '../utils/cnj';
import BottomSheet from '../BottomSheet';
import Media from '../Media';
import Flex from '../Flex';
import { flushSync } from 'react-dom';

type FunctionButtonComponent = (visible?: boolean) => React.ReactElement;

interface PopperMenuProps {
  buttonComponent: any;
  popperContainerClassName?: string;
  bottomSheetClassName?: string;
  menuClassName?: string;
  children: React.ReactNode;
  offsetX?: number;
  offsetY?: number;
  clickCallBack?: (e: any) => void;
  placement?: PopperPlacementType;
  disablePortal?: boolean;
  hasArrow?: boolean;
  closeOnScroll?: boolean;
  showWithHover?: boolean;
  hoverDelay?: number;
  closeDelay?: number;
  noDrawer?: boolean;
  onClose?: () => void;
  onCloseOutside?: () => void;
  onOpen?: () => void;
  disableCloseOnClickOutSide?: boolean;
  disableCloseOnClickInSide?: boolean;
  noBottomSheetForMobile?: boolean;
  classNames?: {
    arrow: string;
    [x: string]: string;
  };
}

const POPPER_CLASS = 'POPPER_CLASS';

const PopperMenu = (
  {
    buttonComponent,
    menuClassName,
    popperContainerClassName,
    bottomSheetClassName,
    children,
    offsetX = 0,
    offsetY = 4,
    placement,
    disablePortal,
    hasArrow,
    clickCallBack,
    showWithHover,
    hoverDelay = 300,
    closeDelay = 1,
    closeOnScroll,
    disableCloseOnClickInSide,
    noDrawer = false,
    onClose,
    onCloseOutside,
    onOpen,
    disableCloseOnClickOutSide,
    classNames,
    noBottomSheetForMobile = false,
  }: PopperMenuProps,
  ref: any
) => {
  const [visible, setVisible] = useStateCallback(false);
  const ButtonComponent = isFunction(buttonComponent)
    ? buttonComponent(visible)
    : buttonComponent;
  const handleSetInvisible = () => setVisible(false, onClose);
  const handleSetInvisibleOutside = () =>
    setVisible(false, () => onCloseOutside?.());
  const currentElementRef = useRef<HTMLElement>();
  const inTimeout = useRef(false);
  const referenceRef = useRef<{
    contains: Function;
  }>(null);
  const popperRef = useRef<{
    contains: Function;
  }>(null);
  // const arrowRef = useRef(null);
  const [arrowRef, setArrowRef] = React.useState(null);
  const bottomSheetRef = useRef(null);

  useImperativeHandle(ref, () => ({
    close() {
      handleSetInvisible();
    },
    open() {
      setVisible(true, onOpen);
    },
  }));
  const handleDocumentClick = (event: any) => {
    if (!disableCloseOnClickOutSide) {
      const popperList = Array.from(
        document.getElementsByClassName(POPPER_CLASS)
      );
      if (
        !referenceRef.current?.contains(event.target) &&
        !popperRef.current?.contains(event.target) &&
        !popperList.some((el) => el?.contains(event.target))
      ) {
        if (onCloseOutside) {
          handleSetInvisibleOutside();
        } else {
          handleSetInvisible();
        }
      }
    }
  };
  const handleClose = (e: any) => {
    setTimeout(() => {
      handleSetInvisible();
    }, closeDelay);
    e?.stopPropagation();
  };

  const visiblePopper = (e: any, value?: boolean) => {
    try {
      clickCallBack?.(e);
      setVisible((v) => {
        if (value) v = !value;
        if (!v && isFunction(onOpen)) {
          onOpen();
        } else if (v && isFunction(onClose)) {
          onClose();
        }
        return !v;
      });
    } catch (error) {
      console.log(error);
    }
  };

  const toggleVisibleMouseLeave = (e: any) => {
    try {
      if (showWithHover) {
        visiblePopper(e, false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onMouseLeaveButtonOrPopper = async (e: any) => {
    if (!showWithHover) return;
    if (showWithHover && !visible) return;
    if (inTimeout.current) return;
    inTimeout.current = true;

    await new Promise((res) => {
      setTimeout(() => {
        res(true);
      }, hoverDelay / 2);
    });
    inTimeout.current = false;
    if (
      popperRef?.current?.contains(currentElementRef.current) ||
      referenceRef?.current?.contains(currentElementRef.current)
    )
      return;
    toggleVisibleMouseLeave(e);
  };

  const toggleVisible = async (e: any) => {
    if (disablePortal) return;
    preventClickHandler(e);

    if (isFunction(ButtonComponent?.props?.onClick)) {
      ButtonComponent?.props?.onClick(e);
    }

    if (isFunction(ButtonComponent?.props?.onMouseEnter)) {
      ButtonComponent?.props?.onMouseEnter(e);
    }
    try {
      if (showWithHover) {
        if (visible) return false;
        await new Promise((res) => {
          setTimeout(() => {
            res(true);
          }, hoverDelay);
        });
        if (inTimeout.current) return false;

        if (
          popperRef?.current?.contains(currentElementRef.current) ||
          referenceRef?.current?.contains(currentElementRef.current)
        ) {
          visiblePopper(e, true);
          return true;
        }
        return false;
      } else {
        visiblePopper(e);
        return true;
      }
    } catch (error) {
      return false;
    }
  };
  const Button = cloneElement(ButtonComponent, {
    ref: referenceRef,
    ...(showWithHover
      ? {
          onMouseEnter: toggleVisible,
          onMouseLeave: onMouseLeaveButtonOrPopper,
          onClick: () => (visible ? setVisible(false) : undefined),
        }
      : {
          onClick: toggleVisible,
        }),
  });

  useEffect(() => {
    function setCurrentElementRef(e: any) {
      currentElementRef.current = e?.target;
    }
    document.addEventListener('mousedown', handleDocumentClick);
    window.addEventListener('resize', handleClose);
    window.addEventListener('mousemove', setCurrentElementRef);

    const close = (e: any) => {
      if (popperRef?.current?.contains?.(e?.target)) return;
      flushSync(handleSetInvisible);
    };

    if (closeOnScroll) {
      window.addEventListener('scroll', close, true);
    }

    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
      window.removeEventListener('resize', handleClose);
      window.addEventListener('mousemove', setCurrentElementRef);

      if (closeOnScroll) {
        window.removeEventListener('scroll', close);
      }
    };
  }, [disableCloseOnClickOutSide, handleSetInvisible]);

  const onClickHandler = (e: any) => {
    if (!disableCloseOnClickInSide) {
      handleClose(e);
    }
  };

  return (
    <>
      {Button}
      <Media greaterThan={noDrawer ? undefined : 'tablet'}>
        <Popper
          // @ts-ignore
          ref={popperRef}
          disablePortal={disablePortal}
          open={visible as boolean}
          className={cnj(
            POPPER_CLASS,
            classes.popperContainer,
            popperContainerClassName
          )}
          // @ts-ignore
          anchorEl={referenceRef.current}
          placement={placement}
          modifiers={{
            flip: {
              enabled: true,
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: 'viewport',
            },
            arrow: {
              enabled: hasArrow,
              element: arrowRef,
            },
            offset: {
              offset: `${offsetX}, ${offsetY}`,
            },
          }}
        >
          <Flex
            className={cnj(classes.popperWrapper, menuClassName)}
            onClick={onClickHandler}
            onMouseLeave={onMouseLeaveButtonOrPopper}
          >
            {children}
          </Flex>
          {hasArrow ? (
            <Flex
              as="span"
              className={cnj(classes.arrow, classNames?.arrow)}
              ref={setArrowRef}
            />
          ) : null}
        </Popper>
      </Media>
      {!noDrawer && (
        <Media lessThan="midDesktop">
          {noBottomSheetForMobile ? (
            <Flex>{visible && children}</Flex>
          ) : (
            <BottomSheet
              ref={bottomSheetRef}
              open={visible}
              onRequestClose={handleClose}
              modalElementClass={bottomSheetClassName}
            >
              {children}
            </BottomSheet>
          )}
        </Media>
      )}
    </>
  );
};

export default forwardRef<any, PopperMenuProps>(PopperMenu);
