import React, { forwardRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import Flex from '../Flex';
// import type { ScrollbarProps } from 'react-custom-scrollbars';

// type ScrollViewProps = Partial<ScrollbarProps>;
type ScrollViewProps = any;

const ScrollView = (
  { id, viewClassName, ...rest }: ScrollViewProps,
  ref: any
) => (
  <Scrollbars
    {...rest}
    ref={ref}
    universal
    renderView={(inrProps: any) => (
      <Flex {...inrProps} id={id} className={viewClassName} />
    )}
  />
);

export default forwardRef<any, ScrollViewProps>(ScrollView);
