import * as React from 'react';

const EmptyFlag = (props: any) => {
  const { fill, ...rest } = props;

  return (
    <svg
      width={100}
      height={134}
      viewBox="0 0 100 134"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M64.223 21.297L45.698 10.696H12.952V.095H.602v148.2h12.35V63.7h29.004L60.48 74.301h38.922V21.296h-35.18z"
        fill={fill}
      />
    </svg>
  );
};

export default EmptyFlag;
