import { useContext } from 'react';
import { themeContext } from './makeStyle';

const useMedia = (): BreakPoints => {
  const {
    isSmallMobile,
    isMoreThanSmallMobile,
    isTabletAndLess,
    isTablet,
    isMoreThanTablet,
    isMidDesktop,
    isDesktop,
  } = useContext<BreakPoints>(themeContext as any);

  return {
    isSmallMobile,
    isMoreThanSmallMobile,
    isTabletAndLess,
    isTablet,
    isMoreThanTablet,
    isMidDesktop,
    isDesktop,
  };
};

export default useMedia;
