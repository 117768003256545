import React from 'react';
import cnj from '../utils/cnj';
import Flex from '../Flex';
import classes from './Divider.horizontal.module.scss';

interface DividerProps {
  className?: string;
}

const Divider = ({ className }: DividerProps): JSX.Element => {
  return <Flex className={cnj(classes.divider, className)} />;
};

export default Divider;
