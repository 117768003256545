import React from 'react';
import cnj from '../utils/cnj';
import Flex from '../Flex';
import type { colorsKeys } from '../helpers/theme';
import classes from './Divider.vertical.module.scss';
import useCssVariables from '@shared/hooks/useCssVariables';
import { makeDynamicStyles } from '@lobox/uikit/utils/makeDynamicStyles';

export interface DividerVerticalProps {
  className?: string;
  height?: number;
  color?: colorsKeys;
  distance?: number;
}

const DividerVertical = ({
  className,
  height,
  color = 'techGray_20',
  distance = 16,
}: DividerVerticalProps): JSX.Element => {
  const styles = useCssVariables({
    scope: classes.dividerRoot,
    variables: {
      distance: typeof distance !== 'undefined' ? `${distance}px` : undefined,
    },
  });

  return (
    <>
      {styles}
      <Flex
        {...makeDynamicStyles({
          height: typeof height !== 'undefined' ? `${height}px` : undefined,
        })}
        className={cnj(
          classes.dividerRoot,
          classes[`backgroundColor-${color}`],
          className
        )}
      />
    </>
  );
};

export default DividerVertical;
