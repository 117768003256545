import React, { forwardRef } from 'react';
// @ts-ignore
import Drawer from 'react-drag-drawer';
import cnj from '../utils/cnj';
import classes from './BottomSheet.component.module.scss';
interface IBottomSheet {
  [key: string]: any;
}

const BottomSheet = ({ children, ...rest }: IBottomSheet, ref: any) => {
  return (
    <Drawer
      ref={ref}
      {...rest}
      containerElementClass={cnj(
        classes.bottomSheetContainer,
        rest.containerElementClass
      )}
      modalElementClass={cnj(
        classes.bottomSheetElement,
        rest.modalElementClass
      )}
    >
      {children}
    </Drawer>
  );
};

export default forwardRef(BottomSheet);
