import React, { memo } from 'react';
import cnj from '../utils/cnj';
import Flex from '../Flex';
import classes from './Skeleton.module.scss';

interface SkeletonProps {
  className?: string;
  style?: React.CSSProperties;
}

const Skeleton: React.FC<SkeletonProps> = ({ className, style = {} }) => {
  return <Flex style={style} className={cnj(classes.shimmer, className)} />;
};

export default memo(Skeleton, () => true);
