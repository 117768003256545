import React, { forwardRef } from 'react';
import cnj from '../utils/cnj';
import Badge from '../Badge';
import Flex from '../Flex';
import Image from '../Image';
import classes from './Avatar.component.module.scss';
import { makeDynamicStyles } from '../utils/makeDynamicStyles';
import EmptyFlag from '../shared/svg/EmptyFlag';
import Person from '../shared/svg/Person';
import useTheme from '../utils/useTheme';

export const schema = {
  xxxs: {
    size: 18,
    borderRadius: 2,
    paddingTop: 2,
    borderWidth: 1,
    statusSize: 6.7,
    statusBottom: 0,
    statusRight: 0,
  },
  xs: {
    size: 24,
    borderRadius: 4,
    paddingTop: 3.62,
    borderWidth: 1,
    statusSize: 6.7,
    statusBottom: 0,
    statusRight: 0,
  },
  xxs: {
    size: 32,
    borderRadius: 4,
    paddingTop: 4.05,
    borderWidth: 1,
    statusSize: 12,
    statusBottom: 0,
    statusRight: 0,
  },
  sm: {
    size: 36,
    borderRadius: 4,
    paddingTop: 5.05,
    borderWidth: 2,
    statusSize: 12,
    statusBottom: 0,
    statusRight: 0,
  },
  smd: {
    size: 40,
    borderRadius: 4,
    paddingTop: 5.05,
    borderWidth: 2,
    statusSize: 12,
    statusBottom: 0,
    statusRight: 0,
  },
  md: {
    size: 42,
    borderRadius: 4,
    paddingTop: 6.76,
    borderWidth: 2,
    statusSize: 12,
    statusBottom: 1,
    statusRight: 1,
  },
  xmd: {
    size: 48,
    borderRadius: 4,
    paddingTop: 6.76,
    borderWidth: 2,
    statusSize: 12,
    statusBottom: 1,
    statusRight: 1,
  },
  fmd: {
    size: 52,
    borderRadius: 4,
    paddingTop: 6.76,
    borderWidth: 2,
    statusSize: 11.72,
    statusBottom: 1,
    statusRight: 1,
  },
  slg: {
    size: 68,
    borderRadius: 4,
    paddingTop: 6.76,
    borderWidth: 2,
    statusSize: 12,
    statusBottom: 1,
    statusRight: 1,
  },
  mlg: {
    size: 76,
    borderRadius: 4,
    paddingTop: 6.76,
    borderWidth: 2,
    statusSize: 12,
    statusBottom: 1,
    statusRight: 1,
  },
  flg: {
    size: 82,
    borderRadius: 4,
    paddingTop: 6.76,
    borderWidth: 2,
    statusSize: 12,
    statusBottom: 1,
    statusRight: 1,
  },
  lg: {
    size: 90,
    borderRadius: 8,
    paddingTop: 13.76,
    borderWidth: 2,
    statusSize: 20,
    statusBottom: 4,
    statusRight: 6,
  },
  vlg: {
    size: 120,
    borderRadius: 8,
    paddingTop: 13.76,
    borderWidth: 2,
    statusSize: 20,
    statusBottom: 4,
    statusRight: 6,
  },
  xl: {
    size: 160,
    borderRadius: 8,
    paddingTop: 22.1,
    borderWidth: 4,
    statusSize: 24,
    statusBottom: 12,
    statusRight: 14,
  },
};

export type AvatarSizeType = keyof typeof schema;

export type ImageResolutionType = 'tiny' | 'small' | 'medium' | 'original';

export interface AvatarProps {
  onClick?: Function | any;
  size?: AvatarSizeType;
  resolution?: ImageResolutionType;
  imgSrc?: string;
  showStatus?: boolean;
  isOnline?: boolean;
  name?: string;
  isCompany?: boolean;
  className?: string;
  badgeNumber?: string;
  avatarInnerClassName?: string;
  bordered?: boolean;
  defaultTag?: boolean;
}

const Avatar = (
  {
    onClick,
    size = 'xs',
    resolution = 'tiny',
    imgSrc,
    showStatus,
    isOnline,
    name,
    isCompany,
    className,
    badgeNumber,
    avatarInnerClassName,
    bordered,
    defaultTag,
  }: AvatarProps,
  ref: any
) => {
  const { theme } = useTheme();
  const Placeholder = isCompany ? EmptyFlag : Person;

  const activeSchema = schema[size];

  return (
    <Flex
      ref={ref}
      className={cnj(classes.avatarRoot, className)}
      style={{
        width: activeSchema.size,
        height: activeSchema.size,
        minWidth: activeSchema.size,
        minHeight: activeSchema.size,
      }}
      onClick={onClick}
    >
      <Flex
        className={cnj(
          classes.avatarInnerWrapper,
          bordered && classes.bordered,
          avatarInnerClassName
        )}
        style={{
          borderRadius: isCompany ? activeSchema.borderRadius : undefined,
          borderWidth: bordered ? activeSchema.borderWidth : undefined,
        }}
      >
        {imgSrc ? (
          <Image
            defaultTag={defaultTag}
            width={activeSchema?.size}
            height={activeSchema?.size}
            className={classes.avatarImage}
            src={imgSrc}
            resolution={resolution}
            alt={name || '-'}
          />
        ) : (
          <Placeholder
            className={classes.avatarImage}
            fill={theme.colors.colorIconEighth}
            style={{ marginTop: activeSchema.paddingTop }}
          />
        )}
      </Flex>
      {showStatus && !isCompany && (
        <Flex
          className={cnj(
            classes.avatarStatus,
            isOnline && classes.avatarStatusOnline
          )}
          {...makeDynamicStyles({
            width: activeSchema.statusSize,
            height: activeSchema.statusSize,
            minWidth: activeSchema.statusSize,
            minHeight: activeSchema.statusSize,
            borderWidth: activeSchema?.borderWidth,
            bottom: activeSchema?.statusBottom || 0,
            right: activeSchema?.statusRight || 0,
          })}
        />
      )}
      {!!badgeNumber && <Badge className={classes.badge} text={badgeNumber} />}
    </Flex>
  );
};

export default forwardRef(Avatar);
