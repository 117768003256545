import * as React from 'react';
import useTheme from '@lobox/uikit/utils/useTheme';

function Private(props: React.SVGProps<SVGSVGElement>) {
  const { isDark } = useTheme();

  return isDark ? (
    <svg
      width="220"
      height="153"
      viewBox="0 0 220 153"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.14067 148.804C8.14067 148.804 -11.3453 111.646 9.97796 71.4204C28.8921 35.7351 58.7462 64.117 75.6554 27.8008C98.7244 -21.7266 213.826 -2.38789 199.249 73.8103C192.197 110.681 231.268 112.029 216.73 148.88L8.14067 148.804Z"
        fill="url(#paint0_linear_4514_36563)"
      />
      <path
        opacity="0.5"
        d="M179.422 38.1426H40.8048C39.4906 38.1426 38.4484 39.8304 38.4484 41.9585L38.1765 141.763C38.1765 143.892 39.2187 145.579 40.5329 145.579L179.467 143.745C180.781 143.745 181.824 142.057 181.824 139.929V41.9585C181.778 39.8304 180.736 38.1426 179.422 38.1426Z"
        fill="white"
        fillOpacity="0.1"
      />
      <path
        opacity="0.05"
        d="M163.697 55.4492H54.1269C53.0393 55.4492 52.1331 56.5868 52.1331 58.0836V71.5549C52.1331 72.9918 52.994 74.1893 54.1269 74.1893H163.697C164.785 74.1893 165.691 73.0517 165.691 71.5549V58.0836C165.646 56.6467 164.785 55.4492 163.697 55.4492Z"
        fill="#F1F4F9"
      />
      <path
        opacity="0.1"
        d="M72.923 55.7324H53.9335C53.1034 55.7324 52.4116 56.8354 52.4116 58.2866V71.3477C52.4116 72.7409 53.0688 73.9019 53.9335 73.9019H72.8885C73.7186 73.9019 74.4104 72.7409 74.4104 71.3477V58.3446C74.445 56.8934 73.7532 55.7324 72.923 55.7324Z"
        fill="#F1F4F9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M178.561 43.8418H174.89C174.709 43.8418 174.528 44.0813 174.528 44.3208C174.528 44.5603 174.709 44.7998 174.89 44.7998H178.561C178.742 44.7998 178.923 44.5603 178.923 44.3208C178.923 44.0813 178.787 43.8418 178.561 43.8418Z"
        fill="#8493A8"
        fillOpacity="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M178.561 46.1172H174.89C174.709 46.1172 174.528 46.3567 174.528 46.5962C174.528 46.8357 174.709 47.0751 174.89 47.0751H178.561C178.742 47.0751 178.923 46.8357 178.923 46.5962C178.923 46.3567 178.787 46.1172 178.561 46.1172Z"
        fill="#8493A8"
        fillOpacity="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M178.561 48.332H174.89C174.709 48.332 174.528 48.5715 174.528 48.811C174.528 49.0505 174.709 49.29 174.89 49.29H178.561C178.742 49.29 178.923 49.0505 178.923 48.811C178.923 48.5715 178.787 48.332 178.561 48.332Z"
        fill="#8493A8"
        fillOpacity="0.2"
      />
      <path
        d="M47.0583 46.2967C47.0583 47.4942 46.3332 48.3922 45.4722 48.3922C44.5659 48.3922 43.8862 47.4343 43.8862 46.2967C43.8862 45.1591 44.6113 44.2012 45.4722 44.2012C46.3332 44.2012 47.0583 45.1591 47.0583 46.2967Z"
        fill="#8493A8"
        fillOpacity="0.1"
      />
      <path
        d="M55.7582 46.2967C55.7582 47.4942 55.0332 48.3922 54.1722 48.3922C53.2659 48.3922 52.5862 47.4343 52.5862 46.2967C52.5862 45.1591 53.3112 44.2012 54.1722 44.2012C55.0332 44.2012 55.7582 45.1591 55.7582 46.2967Z"
        fill="#8493A8"
        fillOpacity="0.2"
      />
      <path
        d="M64.5946 46.2967C64.5946 47.4942 63.8696 48.3922 63.0086 48.3922C62.1023 48.3922 61.4226 47.4343 61.4226 46.2967C61.4226 45.1591 62.1476 44.2012 63.0086 44.2012C63.8696 44.2012 64.5946 45.1591 64.5946 46.2967Z"
        fill="#8493A8"
        fillOpacity="0.1"
      />
      <path
        d="M62.6267 70.2408V70.8636C62.6267 70.8636 62.7718 72.8298 60.7208 73.1107C58.6699 73.4038 56.9815 73.3549 57.0437 71.8406C57.2301 70.6682 57.5616 68.8486 57.5719 67.8716C57.5719 67.5053 57.5512 67.1267 57.4994 66.6993L61.477 66.6016L62.6475 66.8214L62.6267 70.2408Z"
        fill="#FFE5C2"
      />
      <path
        d="M62.6377 66.8331L62.6273 70.2281C62.2027 70.4601 61.7262 70.6067 61.1979 70.6067C59.3749 70.6067 58.1837 69.0313 57.5726 67.8833C57.5726 67.517 57.5518 67.1384 57.5001 66.711L61.4776 66.6133L62.6377 66.8331Z"
        fill="#FED2A4"
      />
      <path
        d="M57.1896 65.954C57.6558 68.885 60.8564 68.7873 62.2444 68.6041C62.6381 68.5552 62.8763 68.4942 62.8763 68.4942C63.3113 68.3598 63.5599 67.5416 63.6739 66.4669C63.9018 64.3542 63.6117 61.2523 63.3113 60.4463C62.8556 59.2495 61.9959 57.955 58.7641 58.8954C55.5427 59.8235 56.5992 62.1927 57.1896 65.954Z"
        fill="#FFE5C2"
      />
      <path
        d="M54.5688 152.635H59.1057C59.0746 152.354 58.9607 151.499 58.7432 150.718C58.6707 150.449 58.5878 150.18 58.4842 149.948C58.0388 148.91 55.2421 149.57 55.2421 149.57C55.2421 149.57 54.9831 149.838 54.776 150.84C54.6828 151.279 54.6102 151.866 54.5688 152.635Z"
        fill="#112B46"
      />
      <path
        d="M68.2934 150.952C68.3038 151.684 68.3866 152.368 68.6041 152.637H77.5847C77.4708 152.258 77.1497 151.672 76.2278 151.501C75.1816 151.306 73.545 150.707 72.5921 149.523C72.2502 149.095 72.0016 148.607 71.8877 148.021C71.463 145.798 68.397 148.705 68.397 148.705C68.397 148.705 68.2727 149.865 68.2934 150.952Z"
        fill="#112B46"
      />
      <path
        d="M62.317 126.965C62.4413 127.783 62.5241 128.443 62.5448 128.919C62.8349 135.489 67.2371 150.498 68.0243 150.852C69.2466 151.389 71.1629 150.241 72.1676 149.863C72.582 149.704 72.8616 149.215 72.7684 148.715C70.5932 136.893 69.7852 119.882 69.4952 109.807C69.3502 104.727 68.6044 101.222 68.6044 101.222C68.6044 101.222 57.8629 97.8388 57.8008 102.113C57.749 105.899 61.3536 120.737 62.317 126.965Z"
        fill="url(#paint1_linear_4514_36563)"
      />
      <path
        d="M62.3167 126.965L69.4846 109.807C69.3396 104.727 68.5938 101.222 68.5938 101.222C68.5938 101.222 57.8523 97.8388 57.7902 102.113C57.7488 105.899 61.3534 120.737 62.3167 126.965Z"
        fill="#233862"
      />
      <path
        d="M64.6992 102.139L59.0229 150.646C59.0229 150.646 56.5266 151.355 54.5896 150.793C54.5896 150.793 52.9323 125.001 53.0669 118.76C53.2016 112.52 51.8757 102.066 53.3673 100.918C54.8693 99.7581 64.6992 102.139 64.6992 102.139Z"
        fill="url(#paint2_linear_4514_36563)"
      />
      <path
        d="M64.3049 57.6619C64.3049 57.6619 62.8858 57.5275 61.2078 56.6727C59.8509 55.9766 58.1314 56.746 57.572 57.0879C57.5824 56.978 57.6238 56.8437 57.7067 56.6971C57.7067 56.6971 57.4581 56.9047 57.4167 57.1978C57.396 57.2222 57.3752 57.2344 57.3856 57.2467C57.3856 57.2467 57.1059 57.1856 56.9195 57.2833C56.9195 57.2833 57.0438 57.2589 57.0956 57.3566C57.0956 57.3688 57.1059 57.381 57.1163 57.381C54.9928 58.59 54.796 62.5467 55.4797 63.4504C56.298 64.5495 57.282 67.0286 57.282 67.0286C57.282 67.0286 57.6446 66.4424 57.4892 65.4777C57.3442 64.5129 58.0485 64.5129 58.9497 64.0733C59.8405 63.6336 59.0947 62.4002 59.8819 61.6553C60.6692 60.9103 63.5384 61.3499 63.5073 60.776C64.5742 60.3119 64.3049 57.7962 64.3049 57.6619Z"
        fill="url(#paint3_linear_4514_36563)"
      />
      <path
        d="M56.5577 72.6953L57.8628 105.754C57.8628 105.754 68.7701 105.961 68.4593 103.055C68.1486 100.148 64.5232 73.7089 64.5232 73.7089L56.5577 72.6953Z"
        fill="white"
      />
      <path
        d="M52.6624 91.4278C53.3149 93.7237 52.0927 101.43 51.6473 104.031C51.5541 104.58 51.4919 104.91 51.4919 104.91C53.0042 107.804 58.5355 107.768 58.5355 107.768C59.6024 102.223 60.1514 93.394 60.1514 93.394L55.9045 71.4609L50.5597 77.2861C50.5597 77.2861 50.9636 80.8399 51.4608 84.4425C51.8648 87.3001 52.3102 90.2188 52.6624 91.4278Z"
        fill="url(#paint4_linear_4514_36563)"
      />
      <path
        d="M61.736 66.4301C61.736 66.4301 61.8396 66.8082 62.6476 66.6741C62.6476 66.6741 63.3312 66.5643 63.2794 66.1374C63.2794 66.1374 62.5958 65.8934 61.736 66.4301Z"
        fill="white"
      />
      <path
        d="M67.8692 73.1599C67.8692 73.1599 67.8692 73.1599 67.8692 73.1721C68.1281 73.4652 75.41 81.8061 76.8187 85.0668C78.2585 88.3885 70.2516 94.983 70.2516 94.983C70.2516 94.983 69.7337 95.2273 69.3815 95.1296C69.7544 98.7932 70.1791 102.176 69.9305 103.617C69.4851 106.218 67.4341 107.098 67.4341 106.89C67.4341 106.829 66.6883 97.4133 66.2844 92.504C65.4143 81.9771 63.2287 71.5234 63.2287 71.5234C65.6111 71.8165 67.8692 73.1599 67.8692 73.1599ZM72.1989 86.5078C72.2093 86.4956 72.2093 86.4712 72.2093 86.4589C72.2093 85.9827 70.6659 84.9324 68.9982 83.6868C68.9775 83.6623 68.9671 83.6501 68.9671 83.6501C68.9982 84.6637 68.9982 85.6651 68.9464 86.5811C68.8532 88.303 68.9568 90.3546 69.1329 92.4429C69.9201 90.9774 72.0435 87.0451 72.1989 86.5078Z"
        fill="url(#paint5_linear_4514_36563)"
      />
      <path
        d="M56.2473 71.4251C56.2369 71.1076 57.5006 70.668 57.5006 70.668C57.5006 70.668 58.9508 73.0371 61.3435 73.086C61.3435 73.086 60.6599 74.6491 59.8623 75.0521C59.8519 75.0521 56.3198 73.2936 56.2473 71.4251Z"
        fill="#D9E9F9"
      />
      <path
        d="M61.3426 73.0246C61.3426 73.0246 62.6063 72.1453 62.6166 71.1805C62.6166 71.1805 63.4349 71.0584 63.7871 71.5225C63.7871 71.5225 64.0875 73.1711 63.6939 74.1237C63.6835 74.1359 61.8605 73.8062 61.3426 73.0246Z"
        fill="#D9E9F9"
      />
      <path
        d="M60.4828 72.8889L62.2023 72.4004C62.2023 72.4004 62.4302 72.4248 62.4198 72.7545C62.4095 73.0843 61.933 74.049 61.7258 74.1223C61.5083 74.1956 60.0685 73.5483 60.4828 72.8889Z"
        fill="url(#paint6_linear_4514_36563)"
      />
      <path
        d="M56.4441 71.2538C56.4337 70.9363 57.2935 70.2891 57.2935 70.2891C57.2935 70.2891 58.9508 72.5727 61.3435 72.6216C61.3435 72.6216 60.6599 74.1847 59.8623 74.5877C59.8519 74.5877 56.5269 73.1345 56.4441 71.2538Z"
        fill="white"
      />
      <path
        d="M61.3436 72.6218C61.3436 72.6218 62.628 71.8158 62.628 70.8633C62.628 70.8633 63.4049 71.2663 63.757 71.7303C63.757 71.7303 64.0885 72.7684 63.6845 73.7209C63.6845 73.7209 61.8615 73.4034 61.3436 72.6218Z"
        fill="white"
      />
      <path
        d="M64.6986 95.4336C64.6365 96.2029 64.3361 96.9234 63.8596 97.4486L63.1035 98.279C62.917 98.4866 62.6166 98.4622 62.4509 98.2179L61.5083 96.8013C61.2597 96.4227 61.1354 95.9465 61.1768 95.4702C61.2701 94.4077 61.4254 92.3439 61.5601 90.3655C61.6637 88.7535 61.7569 87.1903 61.7569 86.25C61.7776 83.2092 61.2701 73.5371 61.2701 73.5371L61.8916 73.5737C61.8916 73.5737 63.4246 77.3351 64.2222 82.4398C64.419 83.6976 64.5743 85.041 64.6468 86.421C64.6365 86.4088 64.9576 91.9409 64.6986 95.4336Z"
        fill="url(#paint7_linear_4514_36563)"
      />
      <path
        d="M64.6986 95.4332C64.6365 96.2026 64.3361 96.9231 63.8596 97.4482L63.1035 98.2786C62.917 98.4862 62.6166 98.4618 62.4509 98.2176L61.5083 96.801C61.2597 96.4224 61.1354 95.9461 61.1768 95.4698C61.2701 94.4074 61.4254 92.3435 61.5601 90.3651C63.2692 88.1059 63.9528 84.9796 64.2222 82.4395C64.419 83.6973 64.5743 85.0406 64.6468 86.4206C64.6365 86.4084 64.9576 91.9405 64.6986 95.4332Z"
        fill="url(#paint8_linear_4514_36563)"
      />
      <path
        d="M70.7693 95.5199C70.7693 94.6772 70.1374 94.0055 69.4331 94.1277C69.2363 94.1643 69.0602 94.262 68.9359 94.4452C68.449 95.2146 67.9829 97.425 68.853 97.5349C69.2052 97.5715 69.6506 97.2906 70.0028 96.9731C70.355 96.6556 70.7693 96.1671 70.7693 95.642V95.5199Z"
        fill="#FFE5C2"
      />
      <path
        d="M51.6476 104.043C53.2116 102.126 55.9151 98.3526 55.8219 95.2507C55.6976 90.9153 51.4715 84.4551 51.4715 84.4551C51.8651 87.3127 52.3105 90.2192 52.6626 91.4405C53.3048 93.7241 52.0826 101.43 51.6476 104.043Z"
        fill="#284875"
        fillOpacity="0.3"
      />
      <path
        d="M51.6992 96.4844C51.6992 96.4844 52.8283 97.1438 53.5016 96.8141C54.1852 96.4844 54.9828 96.2279 55.7493 97.2537C56.5158 98.2796 56.6712 98.9879 56.5158 99.3664C56.3501 99.745 54.5788 99.5985 54.2784 99.3664C53.9884 99.1344 53.5119 98.5482 53.2219 98.6459C52.4036 98.9268 51.7199 98.1941 51.3367 97.6079C51.0985 97.266 51.7303 97.095 51.6992 96.4844Z"
        fill="#FFE5C2"
      />
      <path
        d="M55.9058 71.4609C55.9058 71.4609 51.431 72.0471 50.3745 74.343C49.3179 76.6389 43.8281 85.9568 44.0042 89.828C44.1803 93.6993 51.4932 98.0468 51.4932 98.0468C51.4932 98.0468 53.109 97.2408 53.1401 96.3738C53.1608 95.5067 48.0853 90.3165 48.3961 89.3518C48.7068 88.387 54.3002 80.73 54.8389 78.2021C55.3775 75.6741 55.9058 71.4609 55.9058 71.4609Z"
        fill="url(#paint9_linear_4514_36563)"
      />
      <path
        d="M68.9664 83.6373C68.9664 83.6373 68.9767 83.6495 68.9974 83.6739C70.6651 84.9195 72.2085 85.9698 72.2085 86.4461C72.4363 85.6034 70.2611 82.0985 69.5568 81.378C68.8524 80.6575 68.9664 83.6373 68.9664 83.6373Z"
        fill="#3E6297"
        fillOpacity="0.3"
      />
      <path
        d="M57.2937 70.2891C57.2937 70.2891 60.6912 87.0808 60.1526 93.3945C60.1526 93.3945 58.8992 86.6412 55.8953 81.0968C55.8953 81.0968 56.9933 79.9611 56.9311 79.5337C56.9311 79.5337 56.1128 79.2528 55.3256 79.436C55.3256 79.436 55.4085 72.1942 55.9057 71.437C56.3925 70.6921 57.2937 70.2891 57.2937 70.2891Z"
        fill="url(#paint10_linear_4514_36563)"
      />
      <path
        d="M62.628 70.8634C62.5555 70.8145 65.9944 87.2643 66.2844 92.5156C66.2844 92.5156 65.9426 83.161 65.9737 80.4622C65.9737 80.4622 64.565 79.5829 64.565 79.2776C64.565 78.9723 66.0669 78.1296 66.0669 78.1296C66.0669 78.1296 65.1139 72.3655 64.596 71.7305C64.0781 71.0954 62.628 70.8634 62.628 70.8634Z"
        fill="url(#paint11_linear_4514_36563)"
      />
      <path
        d="M161.651 147.881C161.651 148.059 161.651 148.297 161.601 148.535C161.55 149.248 160.893 149.664 160.741 149.783C160.843 149.783 160.893 149.783 160.944 149.842C162.056 150.377 162.662 150.555 163.724 150.02C163.825 149.961 163.875 149.961 163.977 150.02C163.926 149.426 163.825 148.535 163.774 147.881H161.651Z"
        fill="#FFE5C2"
      />
      <path
        d="M164.179 151.982C164.179 151.448 164.179 150.853 164.179 150.437C164.179 150.14 163.926 149.962 163.724 150.081C162.662 150.615 162.056 150.497 160.944 149.902C160.084 149.486 157.405 151.269 156.748 151.745C156.394 152.042 156.596 152.636 157.001 152.636H163.623C163.926 152.636 164.179 152.339 164.179 151.982Z"
        fill="#112B46"
      />
      <path
        d="M172.57 147.406C172.57 147.585 172.57 147.822 172.519 148.06C172.469 148.773 171.812 149.189 171.66 149.308C171.761 149.308 171.812 149.308 171.862 149.368C172.974 149.903 173.581 150.081 174.643 149.546C174.744 149.486 174.794 149.486 174.895 149.546C174.845 148.952 174.744 148.06 174.693 147.406H172.57Z"
        fill="#FFE5C2"
      />
      <path
        d="M175.148 151.508C175.148 150.973 175.148 150.379 175.148 149.963C175.148 149.665 174.895 149.487 174.693 149.606C173.631 150.141 173.025 150.022 171.913 149.428C171.054 149.012 168.375 150.795 167.717 151.27C167.364 151.567 167.566 152.162 167.97 152.162H174.592C174.845 152.162 175.097 151.864 175.148 151.508Z"
        fill="#112B46"
      />
      <path
        d="M173.075 102.592C173.075 103.662 173.732 106.634 173.732 109.962C173.732 116.322 171.003 124.821 171.003 124.821L175.603 147.763L171.71 147.882L163.117 125.475L163.724 101.463L173.075 102.592Z"
        fill="url(#paint12_linear_4514_36563)"
      />
      <path
        d="M169.992 101.344C169.992 101.344 169.386 108.833 168.071 114.301C167.01 118.878 159.175 125.891 159.175 125.891L164.28 148.179H161.096L149.723 126.485C149.419 125.534 149.47 124.524 149.824 123.632L160.691 99.3828L169.992 101.344Z"
        fill="url(#paint13_linear_4514_36563)"
      />
      <path
        d="M143.202 97.3008H139.107C139.107 97.3008 139.309 100.154 143.555 100.154L143.202 97.3008Z"
        fill="#FFE5C2"
      />
      <path
        d="M165.139 72.993C165.19 72.7553 165.24 72.1015 165.19 71.7449C165.089 70.259 164.331 70.0212 163.421 70.9128C163.421 70.9128 162.612 70.3184 162.208 69.1891C161.955 68.5354 160.843 69.0108 160.034 68.8325C159.326 70.9128 159.983 73.8251 161.5 75.1327C162.106 75.6676 162.713 75.727 163.471 75.727L163.673 79.412L167.515 78.2233L167.465 76.0242C165.948 76.1431 164.887 74.6572 165.139 72.993Z"
        fill="#FFE5C2"
      />
      <path
        d="M162.157 148.179C161.349 146.574 151.391 126.604 152.857 124.167C154.373 121.611 161.197 105.861 161.197 105.861L159.579 103.424C159.579 103.424 159.074 103.899 158.316 104.672L149.874 123.632C149.52 124.523 149.47 125.534 149.773 126.485L161.146 148.179H162.157Z"
        fill="url(#paint14_linear_4514_36563)"
      />
      <path
        d="M160.843 83.3941C161.045 78.8175 167.111 78.4609 167.717 78.4609C168.576 78.4609 169.385 78.9959 169.941 79.7091C170.902 80.9572 172.772 83.7507 172.165 87.614C170.649 97.5991 173.783 104.018 174.187 107.109C168.576 109.783 156.698 106.455 156.698 106.455L160.337 90.2291L160.843 83.3941Z"
        fill="url(#paint15_linear_4514_36563)"
      />
      <path
        d="M156.344 94.2703C156.344 94.2703 160.034 78.5199 164.735 79.4115C169.942 80.4219 161.349 99.9761 161.349 99.9761L143.556 100.392L143.202 97.0637L156.344 94.2703Z"
        fill="url(#paint16_linear_4514_36563)"
      />
      <path
        d="M164.027 99.5008C163.168 102.591 160.236 102.354 160.236 102.354L158.619 107.049C157.405 106.752 156.647 106.574 156.647 106.574L158.063 100.155L161.298 100.095C161.298 100.095 167.262 86.4845 166.251 81.373C166.454 81.7297 167.919 85.8307 164.027 99.5008Z"
        fill="url(#paint17_linear_4514_36563)"
      />
      <path
        d="M169.082 88.9807C163.067 88.6241 165.089 74.8351 165.139 72.1605C165.19 70.6747 164.28 70.0209 163.421 70.9124C163.421 70.9124 162.612 70.3181 162.208 69.1888C161.955 68.4756 160.59 69.0105 159.781 68.8322C161.5 60.6895 169.638 61.0462 173.227 72.1011C176.31 81.6108 178.686 89.5751 169.082 88.9807Z"
        fill="url(#paint18_linear_4514_36563)"
      />
      <path
        d="M163.977 76.5001C164.128 76.3218 164.785 74.8359 164.785 74.8359C164.482 75.1925 163.876 75.5492 163.623 75.668C163.522 75.7275 163.471 75.7275 163.471 75.7275L163.673 79.4125L164.078 79.2936C163.977 78.2237 163.876 76.619 163.977 76.5001Z"
        fill="#FED2A4"
      />
      <path
        d="M159.681 87.2578C160.489 82.9191 162.916 80.185 163.522 79.5312C159.377 81.1954 156.344 94.2712 156.344 94.2712L143.202 97.0646L157.911 96.0542C157.911 96.0542 158.771 92.2504 159.681 87.2578Z"
        fill="url(#paint19_linear_4514_36563)"
      />
      <path
        d="M166.352 65.2661C170.75 67.584 173.227 75.9644 172.064 88.8024C178.181 87.6137 176.007 80.4815 173.277 72.1011C169.689 61.0462 161.55 60.6895 159.832 68.8322C159.781 68.8322 161.955 62.9481 166.352 65.2661Z"
        fill="url(#paint20_linear_4514_36563)"
      />
      <path
        d="M122.183 69.6337C115.95 67.1027 112.636 62.4512 112.636 62.4512C112.636 62.4512 109.267 67.1027 103.089 69.6337C97.4174 71.891 91.4646 71.6174 91.4646 71.6174C91.4646 71.6174 91.4646 96.3115 98.4282 106.23C104.943 115.533 112.58 117.175 112.58 117.175C112.58 117.175 120.218 115.533 126.732 106.23C133.696 96.3115 133.696 71.6174 133.696 71.6174C133.696 71.6174 127.743 71.891 122.183 69.6337Z"
        fill="url(#paint21_linear_4514_36563)"
      />
      <path
        opacity="0.75"
        d="M111.907 113.276C110.166 112.729 104.831 110.472 100.113 103.768C95.8454 97.6116 94.3853 83.5202 93.9922 75.3801C96.4632 75.1749 99.945 74.696 103.314 73.3279C107.189 71.7546 110.053 69.4973 111.907 67.7188C113.76 69.4973 116.624 71.823 120.499 73.3279C123.868 74.696 127.35 75.2433 129.821 75.3801C129.484 83.5202 128.024 97.6116 123.7 103.768C118.983 110.472 113.591 112.729 111.907 113.276Z"
        fill="#83B4FF"
      />
      <path
        d="M111.907 111.976C110.054 111.36 105.224 109.171 100.9 102.946C96.9689 97.337 95.5088 84.5453 95.1157 76.4736C97.5305 76.2 100.619 75.6527 103.652 74.4215C107.246 72.985 109.998 70.9328 111.907 69.1543C113.816 70.8644 116.568 72.9166 120.162 74.4215C123.195 75.6527 126.284 76.2 128.698 76.4736C128.305 84.5453 126.845 97.337 122.914 102.946C118.534 109.171 113.704 111.36 111.907 111.976Z"
        fill="url(#paint22_linear_4514_36563)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124.269 89.6663C123.326 92.4256 121.615 94.763 119.426 96.3623L116.583 93.691C117.337 92.6013 117.787 91.1953 117.787 89.6663C117.787 86.1865 115.452 83.3569 112.581 83.3569C110.841 83.3569 109.304 84.3938 108.347 85.9756L105.403 83.2163C107.433 81.6346 109.913 80.7031 112.581 80.7031C117.932 80.7031 122.485 84.429 124.269 89.6663ZM122.297 89.6663C121.383 87.487 119.933 85.7823 118.208 84.6223C119.063 86.0283 119.585 87.7858 119.585 89.6663C119.585 90.879 119.368 92.0741 118.962 93.1637L119.513 93.6734C120.658 92.6013 121.63 91.248 122.297 89.6663ZM106.955 94.7103C106.099 93.3043 105.577 91.5468 105.577 89.6663C105.577 89.4554 105.577 89.2269 105.592 89.016L104.011 87.5397C103.561 88.19 103.17 88.9106 102.836 89.6663C103.779 91.8456 105.229 93.5504 106.955 94.7103ZM117.569 97.4871L115.119 95.1848C114.365 95.6945 113.509 95.9757 112.581 95.9757C109.71 95.9757 107.375 93.1461 107.375 89.6663C107.375 89.1215 107.433 88.5942 107.535 88.0846L103.851 84.6399C102.561 86.0459 101.531 87.7506 100.879 89.6663C102.662 94.9036 107.216 98.6295 112.567 98.6295C114.35 98.6295 116.032 98.2077 117.569 97.4871Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112.581 85.2031C114.611 85.2031 116.265 87.2067 116.265 89.6671C116.265 90.7744 115.931 91.7761 115.38 92.567L109.565 87.1188C110.232 85.9588 111.334 85.2031 112.581 85.2031ZM113.741 93.9027L108.912 89.3859C108.912 89.4914 108.898 89.5793 108.898 89.6847C108.898 92.1452 110.551 94.1487 112.581 94.1487C112.987 94.1136 113.379 94.0433 113.741 93.9027Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.415 80.1412C102.734 79.6491 103.329 79.5612 103.735 79.9303L122.572 97.593C122.978 97.9797 123.051 98.7003 122.746 99.1923C122.427 99.6844 121.833 99.7723 121.426 99.4032L102.589 81.7405C102.183 81.3539 102.111 80.6333 102.415 80.1412Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4514_36563"
          x1="110"
          y1="0.5"
          x2="110"
          y2="148.88"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#363636" />
          <stop offset="1" stopColor="#242424" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4514_36563"
          x1="72.776"
          y1="125.496"
          x2="57.7874"
          y2="125.496"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset="0.1795" stopColor="#444979" />
          <stop offset="0.5045" stopColor="#28395D" />
          <stop offset="0.789" stopColor="#172F4C" />
          <stop offset="1" stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4514_36563"
          x1="64.6909"
          y1="125.828"
          x2="52.6418"
          y2="125.828"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset="0.1795" stopColor="#444979" />
          <stop offset="0.5045" stopColor="#28395D" />
          <stop offset="0.789" stopColor="#172F4C" />
          <stop offset="1" stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_4514_36563"
          x1="59.717"
          y1="56.4166"
          x2="59.717"
          y2="66.9824"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset="0.1795" stopColor="#444979" />
          <stop offset="0.5045" stopColor="#28395D" />
          <stop offset="0.789" stopColor="#172F4C" />
          <stop offset="1" stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_4514_36563"
          x1="60.5997"
          y1="76.9971"
          x2="41.0866"
          y2="96.8564"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_4514_36563"
          x1="77.6353"
          y1="76.9207"
          x2="60.0633"
          y2="103.243"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_4514_36563"
          x1="62.4188"
          y1="73.265"
          x2="60.4066"
          y2="73.265"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset="0.1795" stopColor="#444979" />
          <stop offset="0.5045" stopColor="#28395D" />
          <stop offset="0.789" stopColor="#172F4C" />
          <stop offset="1" stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_4514_36563"
          x1="64.7973"
          y1="85.9901"
          x2="61.1659"
          y2="85.9901"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset="0.1795" stopColor="#444979" />
          <stop offset="0.5045" stopColor="#28395D" />
          <stop offset="0.789" stopColor="#172F4C" />
          <stop offset="1" stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_4514_36563"
          x1="64.7973"
          y1="90.4368"
          x2="61.1659"
          y2="90.4368"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset="0.1795" stopColor="#444979" />
          <stop offset="0.5045" stopColor="#28395D" />
          <stop offset="0.789" stopColor="#172F4C" />
          <stop offset="1" stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_4514_36563"
          x1="56.4623"
          y1="75.5148"
          x2="44.0615"
          y2="96.9084"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_4514_36563"
          x1="60.4388"
          y1="73.8123"
          x2="48.2759"
          y2="83.7183"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3B76D7" />
          <stop offset="0.4327" stopColor="#3361C4" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_4514_36563"
          x1="66.4554"
          y1="74.1649"
          x2="55.8138"
          y2="81.0899"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3B76D7" />
          <stop offset="0.4327" stopColor="#3361C4" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_4514_36563"
          x1="163.126"
          y1="124.694"
          x2="175.613"
          y2="124.694"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset="0.1795" stopColor="#444979" />
          <stop offset="0.5045" stopColor="#28395D" />
          <stop offset="0.789" stopColor="#172F4C" />
          <stop offset="1" stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_4514_36563"
          x1="149.538"
          y1="123.804"
          x2="170.01"
          y2="123.804"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset="0.1795" stopColor="#444979" />
          <stop offset="0.5045" stopColor="#28395D" />
          <stop offset="0.789" stopColor="#172F4C" />
          <stop offset="1" stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_4514_36563"
          x1="149.583"
          y1="125.822"
          x2="162.168"
          y2="125.822"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset="0.1795" stopColor="#444979" />
          <stop offset="0.5045" stopColor="#28395D" />
          <stop offset="0.789" stopColor="#172F4C" />
          <stop offset="1" stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_4514_36563"
          x1="155.88"
          y1="82.9878"
          x2="167.657"
          y2="109.715"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_4514_36563"
          x1="142.117"
          y1="82.5798"
          x2="147.15"
          y2="103.991"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset="0.4327" stopColor="#74A5FB" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_4514_36563"
          x1="156.178"
          y1="85.2882"
          x2="168.896"
          y2="104.45"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_4514_36563"
          x1="167.884"
          y1="63.266"
          x2="167.884"
          y2="88.8998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset="0.1795" stopColor="#444979" />
          <stop offset="0.5045" stopColor="#28395D" />
          <stop offset="0.789" stopColor="#172F4C" />
          <stop offset="1" stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_4514_36563"
          x1="142.252"
          y1="82.2048"
          x2="146.272"
          y2="100.154"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset="0.4327" stopColor="#74A5FB" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_4514_36563"
          x1="167.94"
          y1="63.2655"
          x2="167.94"
          y2="88.6912"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset="0.1795" stopColor="#444979" />
          <stop offset="0.5045" stopColor="#28395D" />
          <stop offset="0.789" stopColor="#172F4C" />
          <stop offset="1" stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_4514_36563"
          x1="89.4904"
          y1="70.7956"
          x2="107.268"
          y2="123.647"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5D84C4" />
          <stop offset="0.4327" stopColor="#5A78B8" />
          <stop offset="0.8061" stopColor="#363D93" />
          <stop offset="1" stopColor="#272E85" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_4514_36563"
          x1="93.5458"
          y1="75.6838"
          x2="107.279"
          y2="117.174"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg
      width="220"
      height="150"
      viewBox="0 0 220 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.04601 148.804C8.04601 148.804 -11.2134 111.646 9.86194 71.4204C28.5562 35.7351 58.0631 64.117 74.7757 27.8008C97.5765 -21.7266 211.339 -2.38789 196.932 73.8103C189.963 110.681 228.579 112.029 214.21 148.88L8.04601 148.804Z"
        fill="url(#paint0_linear_4514_35950)"
      />
      <path
        d="M177.336 36.5742H40.3303C39.0315 36.5742 38.0014 38.262 38.0014 40.3901L37.7327 140.195C37.7327 142.323 38.7628 144.011 40.0616 144.011L177.38 142.176C178.679 142.176 179.709 140.489 179.709 138.361V40.3901C179.665 38.262 178.634 36.5742 177.336 36.5742Z"
        fill="white"
      />
      <path
        d="M161.794 53.7539H53.4978C52.4229 53.7539 51.5271 54.8832 51.5271 56.3691V69.742C51.5271 71.1684 52.3781 72.3572 53.4978 72.3572H161.794C162.869 72.3572 163.765 71.2279 163.765 69.742V56.3691C163.72 54.9426 162.869 53.7539 161.794 53.7539Z"
        fill="#8493A8"
        fillOpacity="0.1"
      />
      <path
        d="M72.0754 54.0352H53.3067C52.4862 54.0352 51.8025 55.13 51.8025 56.5707V69.5365C51.8025 70.9195 52.452 72.072 53.3067 72.072H72.0412C72.8617 72.072 73.5455 70.9195 73.5455 69.5365V56.6283C73.5796 55.1877 72.8959 54.0352 72.0754 54.0352Z"
        fill="#8493A8"
        fillOpacity="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M176.485 42.2324H172.857C172.678 42.2324 172.499 42.4702 172.499 42.7079C172.499 42.9456 172.678 43.1834 172.857 43.1834H176.485C176.664 43.1834 176.843 42.9456 176.843 42.7079C176.843 42.4702 176.709 42.2324 176.485 42.2324Z"
        fill="#8493A8"
        fillOpacity="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M176.485 44.4902H172.857C172.678 44.4902 172.499 44.728 172.499 44.9657C172.499 45.2035 172.678 45.4412 172.857 45.4412H176.485C176.664 45.4412 176.843 45.2035 176.843 44.9657C176.843 44.728 176.709 44.4902 176.485 44.4902Z"
        fill="#8493A8"
        fillOpacity="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M176.485 46.6895H172.857C172.678 46.6895 172.499 46.9272 172.499 47.1649C172.499 47.4027 172.678 47.6404 172.857 47.6404H176.485C176.664 47.6404 176.843 47.4027 176.843 47.1649C176.843 46.9272 176.709 46.6895 176.485 46.6895Z"
        fill="#8493A8"
        fillOpacity="0.2"
      />
      <path
        d="M46.5111 44.6681C46.5111 45.8568 45.7945 46.7484 44.9435 46.7484C44.0478 46.7484 43.376 45.7974 43.376 44.6681C43.376 43.5389 44.0926 42.5879 44.9435 42.5879C45.7945 42.5879 46.5111 43.5389 46.5111 44.6681Z"
        fill="#8493A8"
        fillOpacity="0.1"
      />
      <path
        d="M55.11 44.6681C55.11 45.8568 54.3934 46.7484 53.5424 46.7484C52.6467 46.7484 51.9749 45.7974 51.9749 44.6681C51.9749 43.5389 52.6915 42.5879 53.5424 42.5879C54.3934 42.5879 55.11 43.5389 55.11 44.6681Z"
        fill="#8493A8"
        fillOpacity="0.2"
      />
      <path
        d="M63.8436 44.6681C63.8436 45.8568 63.127 46.7484 62.2761 46.7484C61.3803 46.7484 60.7085 45.7974 60.7085 44.6681C60.7085 43.5389 61.4251 42.5879 62.2761 42.5879C63.127 42.5879 63.8436 43.5389 63.8436 44.6681Z"
        fill="#8493A8"
        fillOpacity="0.1"
      />
      <path
        d="M61.898 67.1041V67.7269C61.898 67.7269 62.0413 69.6931 60.0142 69.9739C57.9872 70.267 56.3184 70.2182 56.3798 68.7039C56.5641 67.5315 56.8917 65.7119 56.902 64.7349C56.902 64.3685 56.8815 63.99 56.8303 63.5625L60.7616 63.4648L61.9185 63.6847L61.898 67.1041Z"
        fill="#FFE5C2"
      />
      <path
        d="M61.9089 63.6964L61.8987 67.0914C61.479 67.3234 61.008 67.4699 60.4859 67.4699C58.6841 67.4699 57.5067 65.8946 56.9027 64.7466C56.9027 64.3803 56.8822 64.0017 56.831 63.5743L60.7623 63.4766L61.9089 63.6964Z"
        fill="#FED2A4"
      />
      <path
        d="M56.5241 62.8173C56.9848 65.7482 60.1482 65.6505 61.5201 65.4674C61.9091 65.4185 62.1446 65.3574 62.1446 65.3574C62.5746 65.2231 62.8203 64.4049 62.9329 63.3302C63.1581 61.2175 62.8715 58.1156 62.5746 57.3096C62.1241 56.1128 61.2744 54.8183 58.0802 55.7587C54.8963 56.6868 55.9405 59.056 56.5241 62.8173Z"
        fill="#FFE5C2"
      />
      <path
        d="M53.9338 149.498H58.418C58.3873 149.217 58.2746 148.363 58.0596 147.581C57.988 147.312 57.9061 147.044 57.8037 146.812C57.3635 145.774 54.5993 146.433 54.5993 146.433C54.5993 146.433 54.3433 146.702 54.1386 147.703C54.0464 148.143 53.9748 148.729 53.9338 149.498Z"
        fill="#112B46"
      />
      <path
        d="M67.4988 147.815C67.5091 148.548 67.591 149.232 67.806 149.5H76.6821C76.5695 149.122 76.2521 148.535 75.341 148.364C74.307 148.169 72.6894 147.571 71.7475 146.386C71.4097 145.959 71.164 145.47 71.0514 144.884C70.6316 142.661 67.6012 145.568 67.6012 145.568C67.6012 145.568 67.4784 146.728 67.4988 147.815Z"
        fill="#112B46"
      />
      <path
        d="M61.592 123.828C61.7149 124.646 61.7968 125.306 61.8172 125.782C62.1039 132.352 66.455 147.361 67.233 147.715C68.4411 148.253 70.3351 147.105 71.3281 146.726C71.7377 146.567 72.0141 146.079 71.9219 145.578C69.772 133.757 68.9735 116.745 68.6868 106.67C68.5435 101.59 67.8063 98.0849 67.8063 98.0849C67.8063 98.0849 57.1898 94.7021 57.1283 98.9764C57.0772 102.762 60.6399 117.6 61.592 123.828Z"
        fill="url(#paint1_linear_4514_35950)"
      />
      <path
        d="M61.5915 123.828L68.676 106.67C68.5327 101.59 67.7956 98.0849 67.7956 98.0849C67.7956 98.0849 57.179 94.7021 57.1176 98.9764C57.0766 102.762 60.6394 117.6 61.5915 123.828Z"
        fill="#233862"
      />
      <path
        d="M63.9465 99.0027L58.3362 147.51C58.3362 147.51 55.8689 148.218 53.9545 147.656C53.9545 147.656 52.3164 121.864 52.4495 115.624C52.5826 109.383 51.2722 98.9295 52.7464 97.7815C54.2309 96.6213 63.9465 99.0027 63.9465 99.0027Z"
        fill="url(#paint2_linear_4514_35950)"
      />
      <path
        d="M63.5567 54.5251C63.5567 54.5251 62.1541 54.3908 60.4956 53.536C59.1545 52.8399 57.455 53.6092 56.9021 53.9512C56.9124 53.8413 56.9533 53.7069 57.0352 53.5604C57.0352 53.5604 56.7895 53.768 56.7486 54.0611C56.7281 54.0855 56.7076 54.0977 56.7179 54.1099C56.7179 54.1099 56.4414 54.0489 56.2572 54.1466C56.2572 54.1466 56.38 54.1221 56.4312 54.2198C56.4312 54.2321 56.4414 54.2443 56.4517 54.2443C54.3529 55.4533 54.1584 59.41 54.8341 60.3137C55.6429 61.4128 56.6155 63.8919 56.6155 63.8919C56.6155 63.8919 56.9738 63.3057 56.8202 62.3409C56.6769 61.3762 57.3731 61.3762 58.2638 60.9365C59.1442 60.4969 58.4071 59.2635 59.1852 58.5185C59.9632 57.7736 62.7991 58.2132 62.7684 57.6393C63.8229 57.1752 63.5567 54.6595 63.5567 54.5251Z"
        fill="url(#paint3_linear_4514_35950)"
      />
      <path
        d="M55.8995 69.5586L57.1895 102.617C57.1895 102.617 67.9699 102.825 67.6627 99.9181C67.3556 97.0116 63.7724 70.5722 63.7724 70.5722L55.8995 69.5586Z"
        fill="white"
      />
      <path
        d="M52.0495 88.2911C52.6945 90.587 51.4864 98.2929 51.0462 100.894C50.9541 101.444 50.8926 101.773 50.8926 101.773C52.3874 104.668 57.8543 104.631 57.8543 104.631C58.9088 99.0867 59.4514 90.2573 59.4514 90.2573L55.2539 68.3242L49.9712 74.1494C49.9712 74.1494 50.3705 77.7032 50.8619 81.3058C51.2612 84.1634 51.7014 87.0821 52.0495 88.2911Z"
        fill="url(#paint4_linear_4514_35950)"
      />
      <path
        d="M61.0179 63.2934C61.0179 63.2934 61.1203 63.6715 61.9189 63.5373C61.9189 63.5373 62.5946 63.4276 62.5434 63.0006C62.5434 63.0006 61.8677 62.7567 61.0179 63.2934Z"
        fill="white"
      />
      <path
        d="M67.0795 70.0232C67.0795 70.0232 67.0795 70.0232 67.0795 70.0354C67.3354 70.3285 74.5326 78.6694 75.9249 81.93C77.348 85.2517 69.4342 91.8463 69.4342 91.8463C69.4342 91.8463 68.9223 92.0906 68.5742 91.9929C68.9428 95.6565 69.3625 99.0393 69.1168 100.48C68.6766 103.082 66.6495 103.961 66.6495 103.753C66.6495 103.692 65.9124 94.2765 65.5131 89.3672C64.6531 78.8403 62.493 68.3867 62.493 68.3867C64.8477 68.6798 67.0795 70.0232 67.0795 70.0232ZM71.3589 83.3711C71.3691 83.3589 71.3691 83.3344 71.3691 83.3222C71.3691 82.8459 69.8437 81.7957 68.1954 80.5501C68.1749 80.5256 68.1647 80.5134 68.1647 80.5134C68.1954 81.527 68.1954 82.5284 68.1442 83.4443C68.0521 85.1663 68.1545 87.2179 68.3285 89.3062C69.1066 87.8407 71.2053 83.9084 71.3589 83.3711Z"
        fill="url(#paint5_linear_4514_35950)"
      />
      <path
        d="M55.5929 68.2884C55.5827 67.9709 56.8317 67.5312 56.8317 67.5312C56.8317 67.5312 58.265 69.9004 60.6299 69.9493C60.6299 69.9493 59.9542 71.5124 59.1659 71.9154C59.1557 71.9154 55.6646 70.1569 55.5929 68.2884Z"
        fill="#D9E9F9"
      />
      <path
        d="M60.6288 69.8879C60.6288 69.8879 61.8778 69.0086 61.8881 68.0438C61.8881 68.0438 62.6969 67.9217 63.0449 68.3858C63.0449 68.3858 63.3418 70.0344 62.9528 70.9869C62.9426 70.9992 61.1407 70.6694 60.6288 69.8879Z"
        fill="#D9E9F9"
      />
      <path
        d="M59.779 69.7522L61.4785 69.2637C61.4785 69.2637 61.7037 69.2881 61.6935 69.6178C61.6833 69.9475 61.2123 70.9123 61.0076 70.9856C60.7926 71.0589 59.3695 70.4116 59.779 69.7522Z"
        fill="url(#paint6_linear_4514_35950)"
      />
      <path
        d="M55.7874 68.1171C55.7772 67.7996 56.6269 67.1523 56.6269 67.1523C56.6269 67.1523 58.265 69.436 60.6299 69.4849C60.6299 69.4849 59.9542 71.048 59.1659 71.451C59.1556 71.451 55.8693 69.9978 55.7874 68.1171Z"
        fill="white"
      />
      <path
        d="M60.6297 69.4851C60.6297 69.4851 61.8992 68.6791 61.8992 67.7266C61.8992 67.7266 62.667 68.1296 63.0151 68.5936C63.0151 68.5936 63.3427 69.6317 62.9435 70.5842C62.9435 70.5842 61.1416 70.2667 60.6297 69.4851Z"
        fill="white"
      />
      <path
        d="M63.9459 92.2968C63.8845 93.0662 63.5876 93.7867 63.1166 94.3119L62.3693 95.1423C62.185 95.3499 61.8881 95.3255 61.7243 95.0812L60.7927 93.6646C60.547 93.286 60.4241 92.8098 60.4651 92.3335C60.5572 91.271 60.7108 89.2072 60.8439 87.2288C60.9462 85.6168 61.0384 84.0536 61.0384 83.1133C61.0589 80.0724 60.5572 70.4004 60.5572 70.4004L61.1715 70.437C61.1715 70.437 62.6867 74.1984 63.475 79.3031C63.6695 80.5609 63.823 81.9043 63.8947 83.2842C63.8845 83.272 64.2018 88.8042 63.9459 92.2968Z"
        fill="url(#paint7_linear_4514_35950)"
      />
      <path
        d="M63.9459 92.2965C63.8845 93.0658 63.5876 93.7864 63.1166 94.3115L62.3693 95.1419C62.185 95.3495 61.8881 95.3251 61.7243 95.0808L60.7927 93.6642C60.547 93.2857 60.4241 92.8094 60.4651 92.3331C60.5572 91.2707 60.7108 89.2068 60.8439 87.2284C62.5331 84.9692 63.2088 81.8429 63.475 79.3027C63.6695 80.5606 63.823 81.9039 63.8947 83.2839C63.8845 83.2717 64.2018 88.8038 63.9459 92.2965Z"
        fill="url(#paint8_linear_4514_35950)"
      />
      <path
        d="M69.946 92.3831C69.946 91.5405 69.3215 90.8688 68.6254 90.991C68.4308 91.0276 68.2568 91.1253 68.1339 91.3085C67.6528 92.0778 67.1921 94.2882 68.052 94.3982C68.4001 94.4348 68.8404 94.1539 69.1884 93.8364C69.5365 93.5189 69.946 93.0304 69.946 92.5053V92.3831Z"
        fill="#FFE5C2"
      />
      <path
        d="M51.0465 100.907C52.5923 98.9894 55.2644 95.2158 55.1722 92.1139C55.0494 87.7786 50.8724 81.3184 50.8724 81.3184C51.2614 84.176 51.7017 87.0825 52.0497 88.3037C52.6845 90.5874 51.4764 98.2933 51.0465 100.907Z"
        fill="#284875"
        fillOpacity="0.3"
      />
      <path
        d="M51.0977 93.3477C51.0977 93.3477 52.2136 94.0071 52.879 93.6774C53.5547 93.3477 54.343 93.0912 55.1006 94.117C55.8582 95.1428 56.0118 95.8512 55.8582 96.2297C55.6944 96.6083 53.9438 96.4618 53.6469 96.2297C53.3602 95.9977 52.8893 95.4115 52.6026 95.5092C51.7938 95.7901 51.1181 95.0574 50.7393 94.4712C50.5039 94.1292 51.1284 93.9583 51.0977 93.3477Z"
        fill="#FFE5C2"
      />
      <path
        d="M55.2551 68.3242C55.2551 68.3242 50.8324 68.9104 49.7882 71.2063C48.7439 73.5022 43.3179 82.8201 43.4919 86.6913C43.666 90.5626 50.8938 94.9101 50.8938 94.9101C50.8938 94.9101 52.4909 94.1041 52.5216 93.2371C52.5421 92.37 47.5256 87.1798 47.8327 86.2151C48.1399 85.2503 53.6683 77.5933 54.2006 75.0653C54.733 72.5374 55.2551 68.3242 55.2551 68.3242Z"
        fill="url(#paint9_linear_4514_35950)"
      />
      <path
        d="M68.1639 80.5005C68.1639 80.5005 68.1741 80.5128 68.1946 80.5372C69.8429 81.7828 71.3683 82.8331 71.3683 83.3094C71.5935 82.4667 69.4436 78.9618 68.7475 78.2413C68.0513 77.5208 68.1639 80.5005 68.1639 80.5005Z"
        fill="#3E6297"
        fillOpacity="0.3"
      />
      <path
        d="M56.6272 67.1523C56.6272 67.1523 59.9852 83.9441 59.4528 90.2578C59.4528 90.2578 58.214 83.5044 55.245 77.9601C55.245 77.9601 56.3303 76.8244 56.2688 76.397C56.2688 76.397 55.46 76.1161 54.682 76.2993C54.682 76.2993 54.7639 69.0574 55.2553 68.3003C55.7365 67.5553 56.6272 67.1523 56.6272 67.1523Z"
        fill="url(#paint10_linear_4514_35950)"
      />
      <path
        d="M61.8995 67.7267C61.8278 67.6778 65.2268 84.1276 65.5134 89.3789C65.5134 89.3789 65.1756 80.0243 65.2063 77.3254C65.2063 77.3254 63.814 76.4462 63.814 76.1409C63.814 75.8356 65.2984 74.9929 65.2984 74.9929C65.2984 74.9929 64.3566 69.2288 63.8447 68.5937C63.3328 67.9587 61.8995 67.7267 61.8995 67.7267Z"
        fill="url(#paint11_linear_4514_35950)"
      />
      <path
        d="M159.771 144.744C159.771 144.922 159.771 145.16 159.721 145.398C159.671 146.111 159.022 146.527 158.872 146.646C158.972 146.646 159.022 146.646 159.072 146.705C160.171 147.24 160.771 147.419 161.82 146.884C161.92 146.824 161.97 146.824 162.07 146.884C162.02 146.289 161.92 145.398 161.87 144.744H159.771Z"
        fill="#FFE5C2"
      />
      <path
        d="M162.269 148.846C162.269 148.311 162.269 147.716 162.269 147.3C162.269 147.003 162.02 146.825 161.82 146.944C160.771 147.479 160.171 147.36 159.072 146.766C158.223 146.349 155.575 148.133 154.925 148.608C154.575 148.905 154.775 149.5 155.175 149.5H161.72C162.02 149.5 162.269 149.202 162.269 148.846Z"
        fill="#112B46"
      />
      <path
        d="M170.563 144.27C170.563 144.448 170.563 144.686 170.513 144.923C170.463 145.637 169.813 146.053 169.664 146.171C169.763 146.171 169.813 146.171 169.863 146.231C170.963 146.766 171.562 146.944 172.611 146.409C172.711 146.35 172.761 146.35 172.861 146.409C172.811 145.815 172.711 144.923 172.661 144.27H170.563Z"
        fill="#FFE5C2"
      />
      <path
        d="M173.111 148.371C173.111 147.836 173.111 147.242 173.111 146.826C173.111 146.529 172.861 146.35 172.661 146.469C171.612 147.004 171.013 146.885 169.913 146.291C169.064 145.875 166.416 147.658 165.767 148.133C165.417 148.431 165.617 149.025 166.017 149.025H172.561C172.811 149.025 173.061 148.728 173.111 148.371Z"
        fill="#112B46"
      />
      <path
        d="M171.062 99.4554C171.062 100.525 171.712 103.497 171.712 106.825C171.712 113.185 169.014 121.684 169.014 121.684L173.56 144.626L169.713 144.745L161.22 122.338L161.82 98.3262L171.062 99.4554Z"
        fill="url(#paint12_linear_4514_35950)"
      />
      <path
        d="M168.015 98.2075C168.015 98.2075 167.415 105.696 166.117 111.164C165.067 115.741 157.324 122.754 157.324 122.754L162.37 145.042H159.222L147.981 123.349C147.681 122.398 147.731 121.387 148.081 120.496L158.822 96.2461L168.015 98.2075Z"
        fill="url(#paint13_linear_4514_35950)"
      />
      <path
        d="M141.536 94.1641H137.489C137.489 94.1641 137.689 97.017 141.886 97.017L141.536 94.1641Z"
        fill="#FFE5C2"
      />
      <path
        d="M163.219 69.8563C163.269 69.6185 163.319 68.9648 163.269 68.6081C163.169 67.1223 162.419 66.8845 161.52 67.776C161.52 67.776 160.721 67.1817 160.321 66.0524C160.071 65.3986 158.972 65.8741 158.173 65.6958C157.473 67.776 158.123 70.6884 159.622 71.996C160.221 72.5309 160.821 72.5903 161.57 72.5903L161.77 76.2753L165.567 75.0866L165.517 72.8875C164.018 73.0064 162.969 71.5205 163.219 69.8563Z"
        fill="#FFE5C2"
      />
      <path
        d="M160.271 145.042C159.472 143.437 149.63 123.467 151.079 121.03C152.577 118.474 159.322 102.724 159.322 102.724L157.723 100.287C157.723 100.287 157.224 100.763 156.474 101.535L148.131 120.495C147.781 121.387 147.731 122.397 148.031 123.348L159.272 145.042H160.271Z"
        fill="url(#paint14_linear_4514_35950)"
      />
      <path
        d="M158.972 80.2573C159.172 75.6808 165.167 75.3242 165.767 75.3242C166.616 75.3242 167.415 75.8591 167.965 76.5724C168.914 77.8205 170.763 80.614 170.163 84.4773C168.664 94.4624 171.762 100.881 172.162 103.972C166.616 106.647 154.875 103.318 154.875 103.318L158.473 87.0924L158.972 80.2573Z"
        fill="url(#paint15_linear_4514_35950)"
      />
      <path
        d="M154.526 91.1336C154.526 91.1336 158.173 75.3832 162.819 76.2747C167.965 77.2851 159.472 96.8393 159.472 96.8393L141.886 97.2554L141.536 93.927L154.526 91.1336Z"
        fill="url(#paint16_linear_4514_35950)"
      />
      <path
        d="M162.12 96.3641C161.27 99.4547 158.373 99.217 158.373 99.217L156.774 103.912C155.575 103.615 154.825 103.437 154.825 103.437L156.224 97.0179L159.422 96.9584C159.422 96.9584 165.317 83.3478 164.318 78.2363C164.518 78.5929 165.967 82.694 162.12 96.3641Z"
        fill="url(#paint17_linear_4514_35950)"
      />
      <path
        d="M167.116 85.844C161.17 85.4874 163.169 71.6984 163.219 69.0238C163.269 67.538 162.369 66.8842 161.52 67.7757C161.52 67.7757 160.721 67.1813 160.321 66.0521C160.071 65.3388 158.722 65.8738 157.923 65.6955C159.621 57.5528 167.665 57.9094 171.212 68.9644C174.26 78.474 176.608 86.4384 167.116 85.844Z"
        fill="url(#paint18_linear_4514_35950)"
      />
      <path
        d="M162.069 73.3634C162.219 73.1851 162.869 71.6992 162.869 71.6992C162.569 72.0558 161.969 72.4124 161.72 72.5313C161.62 72.5907 161.57 72.5907 161.57 72.5907L161.77 76.2757L162.169 76.1569C162.069 75.087 161.97 73.4823 162.069 73.3634Z"
        fill="#FED2A4"
      />
      <path
        d="M157.823 84.1211C158.623 79.7823 161.021 77.0483 161.62 76.3945C157.524 78.0587 154.526 91.1345 154.526 91.1345L141.536 93.9279L156.075 92.9175C156.075 92.9175 156.924 89.1137 157.823 84.1211Z"
        fill="url(#paint19_linear_4514_35950)"
      />
      <path
        d="M164.418 62.1293C168.764 64.4473 171.212 72.8277 170.063 85.6657C176.108 84.477 173.96 77.3448 171.262 68.9644C167.715 57.9094 159.671 57.5528 157.973 65.6955C157.923 65.6955 160.071 59.8114 164.418 62.1293Z"
        fill="url(#paint20_linear_4514_35950)"
      />
      <path
        d="M120.762 66.4969C114.601 63.966 111.326 59.3145 111.326 59.3145C111.326 59.3145 107.996 63.966 101.89 66.4969C96.2844 68.7543 90.4009 68.4807 90.4009 68.4807C90.4009 68.4807 90.4009 93.1748 97.2835 103.093C103.722 112.396 111.271 114.038 111.271 114.038C111.271 114.038 118.82 112.396 125.258 103.093C132.141 93.1748 132.141 68.4807 132.141 68.4807C132.141 68.4807 126.257 68.7543 120.762 66.4969Z"
        fill="url(#paint21_linear_4514_35950)"
      />
      <path
        opacity="0.75"
        d="M110.605 110.14C108.885 109.592 103.612 107.335 98.9492 100.631C94.7308 94.4749 93.2877 80.3835 92.8992 72.2434C95.3414 72.0381 98.7827 71.5593 102.113 70.1912C105.943 68.6179 108.774 66.3606 110.605 64.582C112.437 66.3606 115.268 68.6863 119.098 70.1912C122.428 71.5593 125.869 72.1065 128.312 72.2434C127.978 80.3835 126.535 94.4749 122.261 100.631C117.599 107.335 112.271 109.592 110.605 110.14Z"
        fill="#83B4FF"
      />
      <path
        d="M110.606 108.839C108.774 108.223 104 106.034 99.7266 99.8095C95.8412 94.2003 94.3981 81.4086 94.0095 73.3369C96.3962 73.0633 99.449 72.516 102.446 71.2847C105.999 69.8482 108.718 67.7961 110.606 66.0176C112.493 67.7277 115.213 69.7798 118.765 71.2847C121.762 72.516 124.815 73.0633 127.202 73.3369C126.813 81.4086 125.37 94.2003 121.485 99.8095C117.155 106.034 112.382 108.223 110.606 108.839Z"
        fill="url(#paint22_linear_4514_35950)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M122.824 86.5296C121.892 89.2888 120.201 91.6263 118.037 93.2256L115.227 90.5542C115.973 89.4646 116.417 88.0586 116.417 86.5296C116.417 83.0498 114.11 80.2202 111.272 80.2202C109.552 80.2202 108.033 81.2571 107.087 82.8389L104.177 80.0796C106.184 78.4979 108.634 77.5664 111.272 77.5664C116.56 77.5664 121.061 81.2923 122.824 86.5296ZM120.874 86.5296C119.972 84.3503 118.538 82.6455 116.833 81.4856C117.678 82.8916 118.194 84.6491 118.194 86.5296C118.194 87.7422 117.979 88.9373 117.578 90.027L118.123 90.5366C119.255 89.4646 120.215 88.1113 120.874 86.5296ZM105.711 91.5736C104.865 90.1676 104.349 88.4101 104.349 86.5296C104.349 86.3187 104.349 86.0902 104.363 85.8793L102.801 84.403C102.357 85.0533 101.97 85.7739 101.64 86.5296C102.572 88.7089 104.005 90.4136 105.711 91.5736ZM116.202 94.3504L113.78 92.0481C113.035 92.5578 112.189 92.839 111.272 92.839C108.434 92.839 106.126 90.0094 106.126 86.5296C106.126 85.9848 106.184 85.4575 106.284 84.9478L102.643 81.5032C101.368 82.9092 100.35 84.6139 99.7053 86.5296C101.468 91.7669 105.969 95.4928 111.257 95.4928C113.02 95.4928 114.683 95.071 116.202 94.3504Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M111.272 82.0664C113.278 82.0664 114.912 84.0699 114.912 86.5304C114.912 87.6376 114.583 88.6394 114.038 89.4303L108.291 83.9821C108.95 82.8221 110.039 82.0664 111.272 82.0664ZM112.418 90.766L107.646 86.2492C107.646 86.3547 107.631 86.4425 107.631 86.548C107.631 89.0085 109.265 91.012 111.272 91.012C111.673 90.9769 112.06 90.9066 112.418 90.766Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.224 77.0045C101.54 76.5124 102.127 76.4245 102.529 76.7936L121.147 94.4563C121.548 94.843 121.62 95.5635 121.319 96.0556C121.003 96.5477 120.416 96.6356 120.014 96.2665L101.396 78.6038C100.995 78.2171 100.923 77.4966 101.224 77.0045Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4514_35950"
          x1="108.721"
          y1="0.788655"
          x2="108.721"
          y2="148.88"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DCE9FC" />
          <stop offset="1" stopColor="#DCE9FC" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4514_35950"
          x1="71.9295"
          y1="122.359"
          x2="57.1151"
          y2="122.359"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset="0.1795" stopColor="#444979" />
          <stop offset="0.5045" stopColor="#28395D" />
          <stop offset="0.789" stopColor="#172F4C" />
          <stop offset="1" stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4514_35950"
          x1="63.9383"
          y1="122.692"
          x2="52.0293"
          y2="122.692"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset="0.1795" stopColor="#444979" />
          <stop offset="0.5045" stopColor="#28395D" />
          <stop offset="0.789" stopColor="#172F4C" />
          <stop offset="1" stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_4514_35950"
          x1="59.0221"
          y1="53.2798"
          x2="59.0221"
          y2="63.8457"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset="0.1795" stopColor="#444979" />
          <stop offset="0.5045" stopColor="#28395D" />
          <stop offset="0.789" stopColor="#172F4C" />
          <stop offset="1" stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_4514_35950"
          x1="59.8946"
          y1="73.8604"
          x2="40.3788"
          y2="93.4914"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_4514_35950"
          x1="76.7321"
          y1="73.784"
          x2="59.0821"
          y2="99.9154"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_4514_35950"
          x1="61.6925"
          y1="70.1283"
          x2="59.7037"
          y2="70.1283"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset="0.1795" stopColor="#444979" />
          <stop offset="0.5045" stopColor="#28395D" />
          <stop offset="0.789" stopColor="#172F4C" />
          <stop offset="1" stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_4514_35950"
          x1="64.0434"
          y1="82.8534"
          x2="60.4542"
          y2="82.8534"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset="0.1795" stopColor="#444979" />
          <stop offset="0.5045" stopColor="#28395D" />
          <stop offset="0.789" stopColor="#172F4C" />
          <stop offset="1" stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_4514_35950"
          x1="64.0434"
          y1="87.3001"
          x2="60.4542"
          y2="87.3001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset="0.1795" stopColor="#444979" />
          <stop offset="0.5045" stopColor="#28395D" />
          <stop offset="0.789" stopColor="#172F4C" />
          <stop offset="1" stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_4514_35950"
          x1="55.8052"
          y1="72.3781"
          x2="43.3328"
          y2="93.6451"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_4514_35950"
          x1="59.7357"
          y1="70.6755"
          x2="47.6024"
          y2="80.4426"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3B76D7" />
          <stop offset="0.4327" stopColor="#3361C4" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_4514_35950"
          x1="65.6824"
          y1="71.0282"
          x2="55.0918"
          y2="77.84"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3B76D7" />
          <stop offset="0.4327" stopColor="#3361C4" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_4514_35950"
          x1="161.229"
          y1="121.558"
          x2="173.571"
          y2="121.558"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset="0.1795" stopColor="#444979" />
          <stop offset="0.5045" stopColor="#28395D" />
          <stop offset="0.789" stopColor="#172F4C" />
          <stop offset="1" stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_4514_35950"
          x1="147.798"
          y1="120.667"
          x2="168.032"
          y2="120.667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset="0.1795" stopColor="#444979" />
          <stop offset="0.5045" stopColor="#28395D" />
          <stop offset="0.789" stopColor="#172F4C" />
          <stop offset="1" stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_4514_35950"
          x1="147.843"
          y1="122.686"
          x2="160.282"
          y2="122.686"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset="0.1795" stopColor="#444979" />
          <stop offset="0.5045" stopColor="#28395D" />
          <stop offset="0.789" stopColor="#172F4C" />
          <stop offset="1" stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_4514_35950"
          x1="154.067"
          y1="79.8511"
          x2="165.937"
          y2="106.476"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_4514_35950"
          x1="140.464"
          y1="79.443"
          x2="145.55"
          y2="100.828"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset="0.4327" stopColor="#74A5FB" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_4514_35950"
          x1="154.361"
          y1="82.1515"
          x2="167.137"
          y2="101.175"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_4514_35950"
          x1="165.932"
          y1="60.1293"
          x2="165.932"
          y2="85.7631"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset="0.1795" stopColor="#444979" />
          <stop offset="0.5045" stopColor="#28395D" />
          <stop offset="0.789" stopColor="#172F4C" />
          <stop offset="1" stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_4514_35950"
          x1="140.598"
          y1="79.0681"
          x2="144.66"
          y2="96.9969"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset="0.4327" stopColor="#74A5FB" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_4514_35950"
          x1="165.987"
          y1="60.1288"
          x2="165.987"
          y2="85.5544"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset="0.1795" stopColor="#444979" />
          <stop offset="0.5045" stopColor="#28395D" />
          <stop offset="0.789" stopColor="#172F4C" />
          <stop offset="1" stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_4514_35950"
          x1="88.4497"
          y1="67.6589"
          x2="106.393"
          y2="120.384"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5D84C4" />
          <stop offset="0.4327" stopColor="#5A78B8" />
          <stop offset="0.8061" stopColor="#363D93" />
          <stop offset="1" stopColor="#272E85" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_4514_35950"
          x1="92.4579"
          y1="72.5471"
          x2="106.32"
          y2="113.941"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset="0.4327" stopColor="#5384EE" />
          <stop offset="0.8061" stopColor="#5159C0" />
          <stop offset="1" stopColor="#626DF0" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Private;
