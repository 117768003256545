import React, { useRef } from 'react';
import { Formik, Form as FormikForm } from 'formik';
import { isEmptyObjectValues, useReactMutation } from '@lobox/utils';
import type { FormikProps } from 'formik';

interface InitialValues {
  [name: string]: any;
}

export interface FormProps {
  initialValues: InitialValues;
  onSuccess?: (
    apiResponse: any,
    values?: any,
    formikRef?: FormikProps<any>
  ) => void;
  onFailure?: (error: any, variables: any, formRef?: FormikProps<any>) => any;
  validationSchema?: {};
  enableReinitialize?: boolean;
  url?: string;
  local?: boolean;
  method?: 'POST' | 'DELETE' | 'PUT';
  headers?: any;
  transform?: Function;
  customErrorHandler?: Function;
  apiFunc?: Function;
  children: React.ReactNode | React.FC<FormikProps<any>>;
  customXNames?: Array<string>;
  submitWithEnter?: boolean;
  className?: string;
}

const Form: React.FC<FormProps> = ({
  initialValues,
  validationSchema,
  children,
  onFailure,
  onSuccess: onSuccessHandler,
  enableReinitialize,
  url,
  local,
  method = 'POST',
  headers = {},
  transform,
  customErrorHandler,
  apiFunc,
  customXNames,
  submitWithEnter,
  className,
}) => {
  const formRef = useRef<FormikProps<any>>();

  const onSuccess = (response: any, variables: any) => {
    formRef?.current?.setSubmitting(false);

    return onSuccessHandler?.(
      response.data || response,
      variables,
      formRef.current
    );
  };

  const onErrorHandler = async (error: any, variables: any) => {
    await customErrorHandler?.({ error, variables, form: formRef?.current });
    formRef?.current?.setSubmitting(false);
    onFailure?.(error, variables, formRef.current);
    const fieldErrors = error?.response?.data?.fieldErrors;

    if (fieldErrors?.length > 0) {
      const formErrors = fieldErrors?.reduce(
        (prev: {}, cur: { field: string; defaultMessage: string }) => {
          const field = customXNames?.includes(cur?.field)
            ? `x${cur?.field}`
            : cur?.field;
          const message = cur?.defaultMessage;
          return { ...prev, [field]: message };
        },
        {}
      );
      formRef?.current?.setStatus(formErrors);
    }
  };

  const { mutate } = useReactMutation({ apiFunc, url, headers, method });
  const onSubmitHandler = (values: any, actions: any) => {
    actions.setStatus(undefined);
    const bodyData = transform ? transform(values) : values;
    if (local) {
      return onSuccessHandler?.(bodyData, formRef.current);
    }
    actions.setSubmitting(true);
    mutate(bodyData, { onError: onErrorHandler, onSuccess });
  };

  const handleSubmit = () => {
    const { isSubmitting, dirty, isValid, status } = formRef.current || {};
    if (!isSubmitting && dirty && isValid && isEmptyObjectValues(status)) {
      formRef.current?.handleSubmit();
    }
  };

  return (
    <Formik
      enableReinitialize={enableReinitialize}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmitHandler}
      innerRef={formRef}
      // validateOnBlur={false}
      // validateOnChange={false}
      validateOnMount
    >
      {(props) => (
        <FormikForm
          className={className}
          onSubmit={
            submitWithEnter
              ? (e) => {
                  e.preventDefault();
                  handleSubmit();
                }
              : undefined
          }
          onKeyDown={
            submitWithEnter
              ? (e) => {
                  if (e.key === 'Enter') {
                    handleSubmit();
                  }
                }
              : undefined
          }
        >
          {
            // @ts-ignore
            children(props)
          }
        </FormikForm>
      )}
    </Formik>
  );
};

export default Form;
